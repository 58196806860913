import { IGoalStep, IGoalStepOption } from "models/GoalStep"
import React from "react"
import styled from "styled-components"
import Tag from "./Tag"
import shortid from "shortid"
import { FormControl } from "react-bootstrap"
import AddNewButton from "./AddNewButton"
import RegistrationFormCard from "../../../cards/RegistrationFormCard"

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
`
const Icon = styled.img`
  margin-right: 20px;
`
const Content = styled.div``
const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 8px;
`
const Description = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 16px;
`
const Options = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const Diet = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 94%;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-bottom: 10px;
  & > h1 {
    font-family: Open Sans;
    font-style: normal;
    font-size: 15px;
    width: 100%;
    margin-bottom: 15px;
    color: ${props => props.theme.colors.secondary};
  }
`
const Exercise = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 94%;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-bottom: 10px;
  & > h1 {
    font-family: Open Sans;
    margin-bottom: 15px;
    font-style: normal;

    font-size: 15px;
    width: 100%;
    color: ${props => props.theme.colors.secondary};
  }
`
const Wellbeing = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 94%;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-bottom: 10px;
  & > h1 {
    font-family: Open Sans;
    margin-bottom: 15px;
    font-style: normal;

    font-size: 15px;
    width: 100%;
    color: ${props => props.theme.colors.secondary};
  }
`
const renderDiet = (
  data: IGoalStep,
  onChange: Function,
  onRemove: Function,
  onTagClick: Function
) =>
  (data.options as IGoalStepOption[]).map((option: IGoalStepOption) => {
    if (option.ref === "Diet")
      return (
        <Tag
          key={option.id}
          id={option.id}
          text={option.text}
          title={data.title}
          tagIcon={option.tagIcon}
          selected={option.selected}
          isCustom={option.isCustom}
          onChange={onChange}
          onRemove={onRemove}
          onClick={() => onTagClick(data.id, option)}
        />
      )
  })
const renderExcercise = (
  data: IGoalStep,
  onChange: Function,
  onRemove: Function,
  onTagClick: Function
) =>
  (data.options as IGoalStepOption[]).map((option: IGoalStepOption) => {
    if (option.ref === "Exercise")
      return (
        <Tag
          key={option.id}
          id={option.id}
          text={option.text}
          title={data.title}
          tagIcon={option.tagIcon}
          selected={option.selected}
          isCustom={option.isCustom}
          onChange={onChange}
          onRemove={onRemove}
          onClick={() => onTagClick(data.id, option)}
        />
      )
  })
const renderWellbeing = (
  data: IGoalStep,
  onChange: Function,
  onRemove: Function,
  onTagClick: Function
) =>
  (data.options as IGoalStepOption[]).map((option: IGoalStepOption) => {
    if (option.ref === "Wellbeing")
      return (
        <Tag
          key={option.id}
          id={option.id}
          text={option.text}
          title={data.title}
          tagIcon={option.tagIcon}
          selected={option.selected}
          isCustom={option.isCustom}
          onChange={onChange}
          onRemove={onRemove}
          onClick={() => onTagClick(data.id, option)}
        />
      )
  })
const renderTags = (
  data: IGoalStep,
  onChange: Function,
  onRemove: Function,
  onTagClick: Function
) =>
  (data.options as IGoalStepOption[]).map((option: IGoalStepOption) => (
    <Tag
      key={option.id}
      id={option.id}
      text={option.text}
      title={data.title}
      tagIcon={option.tagIcon}
      selected={option.selected}
      isCustom={option.isCustom}
      onChange={onChange}
      onRemove={onRemove}
      onClick={() => onTagClick(data.id, option)}
    />
  ))

interface Props {
  data: IGoalStep
  onTagClick: Function
  customOptions: IGoalStepOption[] | string
  updateOptions: (stepId: string, options: IGoalStepOption[]) => void
  updateCustomText: Function
}

const GoalStep: React.FC<Props> = (props, option: IGoalStepOption) => {
  const { updateOptions, data } = props

  const add = (text: string, refId: string, tagIcon: string) => {
    const newItems = [
      ...(data.options as IGoalStepOption[]),
      {
        id: shortid.generate(),
        text: text,
        ref: refId,
        tagIcon: tagIcon,
        selected: false,
        isCustom: true,
      },
    ]
    updateOptions(data.id, newItems)
  }

  const onChange = (id: string, text: string) => {
    const stepOpts = data.options as IGoalStepOption[]
    const changedItem = stepOpts.find(option => option.id === id)
    const changedIndex = stepOpts.findIndex(option => option.id === id)
    if (changedItem) {
      changedItem.text = text
      const options = [...stepOpts]
      options.splice(changedIndex, 1, changedItem)
      updateOptions(data.id, options)
    }
  }
  const onRemove = (id: string) => {
    const stepOpts = data.options as IGoalStepOption[]
    console.log(stepOpts)
    const removeIndex = stepOpts.findIndex(option => option.id === id)
    const options = [...stepOpts]
    options.splice(removeIndex, 1)
    updateOptions(data.id, options)
  }
  const onTextInput = (e: any) => {
    props.updateCustomText(data.id, e.target.value)
  }

  console.log(option)
  return (
    <Container>
      <Icon src={props.data.icon} />
      <Content>
        <Title>{props.data.title}</Title>
        <Description>{props.data.description}</Description>
        {props.data.title !== "Pick your goals" ? (
          <Options>
            {props.data.options ? (
              <>
                {renderTags(data, onChange, onRemove, props.onTagClick)}
                <AddNewButton add={add} data={data} refId="" />
              </>
            ) : (
              <FormControl
                value={props.data.customText as string}
                onChange={onTextInput}
                as="textarea"
                rows={3}
                style={{ fontSize: "14px" }}
                placeholder="Type here"
              />
            )}
          </Options>
        ) : (
          <>
            <Diet>
              {props.data.options ? (
                <>
                  <h1>Diet</h1>
                  {renderDiet(data, onChange, onRemove, props.onTagClick)}
                  <AddNewButton add={add} data={data} refId="Diet" />
                </>
              ) : (
                <FormControl
                  value={props.data.customText as string}
                  onChange={onTextInput}
                  as="textarea"
                  rows={3}
                  style={{ fontSize: "14px" }}
                  placeholder="Type here"
                />
              )}
            </Diet>

            <Exercise>
              {props.data.options ? (
                <>
                  <h1>Exercise</h1>

                  {renderExcercise(data, onChange, onRemove, props.onTagClick)}

                  <AddNewButton add={add} data={data} refId="Exercise" />
                </>
              ) : (
                <FormControl
                  value={props.data.customText as string}
                  onChange={onTextInput}
                  as="textarea"
                  rows={3}
                  style={{ fontSize: "14px" }}
                  placeholder="Type here"
                />
              )}
            </Exercise>
            <Wellbeing>
              {props.data.options ? (
                <>
                  <h1>Wellbeing</h1>

                  {renderWellbeing(data, onChange, onRemove, props.onTagClick)}

                  <AddNewButton add={add} data={data} refId="Wellbeing" />
                </>
              ) : (
                <FormControl
                  value={props.data.customText as string}
                  onChange={onTextInput}
                  as="textarea"
                  rows={3}
                  style={{ fontSize: "14px" }}
                  placeholder="Type here"
                />
              )}
            </Wellbeing>
          </>
        )}
      </Content>
    </Container>
  )
}

export default GoalStep
