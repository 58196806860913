import Loader from "components/Loader"
import { ISymptomQuestion } from "models/ISymptomQuestion"
import React from "react"
import styled from "styled-components"
import SymptomQuestion from "./SymptomQuestion"

const Container = styled.div``

interface Props {
  addAnswer: Function
  questions: ISymptomQuestion[]
}

const SymptomsForm: React.FC<Props> = props => {
  if (!props.questions) {
    return <Loader />
  }

  return (
    <Container>
      {props.questions.map((question: any, i: number) => {
        return (
          <div key={i.toString()}>
            <SymptomQuestion {...question} onOptionClick={props.addAnswer} />
          </div>
        )
      })}
    </Container>
  )
}

export default SymptomsForm
