import React from "react"
import styled, { css } from "styled-components"
import { breakpoints } from "utils"

const Container: any = styled.div`
  background: #ffffff;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 20px;

  @media (max-width: ${breakpoints.phone}px) {
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    width: 100%;
    padding: 0px !important;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    border: 1px solid lightgray;
  }

  ${(props: any) => {
    if (props.$background) {
      return css`
        background-image: ${(props: any) => `url(${props.$background})`};
      `
    }
  }}
`
const Wrapper: any = styled.div`
  padding: 16px 16px;
  width: 100%;
  /* background-color: red; */
  ${(props: any) => {
    if (props.$hasBg) {
      return css`
        & div {
          color: white;
        }
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.75) -17.74%,
          rgba(0, 0, 0, 0) 129.68%
        );
      `
    }
  }}
  @media (max-width: ${breakpoints.phone}px) {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    border-radius: 7px;
    border: 1px solid lightgray;
  }
`
const Title = styled.div`
  font-family: Open Sans;
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px 5px 12px;
  background-color: ${props => props.theme.colors.secondary};
  font-style: normal;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: ${props => props.theme.colors.white};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const Subtitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: ${props => props.theme.colors.secondary};
`

interface Props {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  bgCoverImg?: string
  className?: string
}

const Card: React.FC<Props> = props => {
  return (
    <Container className={props.className} $background={props.bgCoverImg}>
      <Wrapper $hasBg={!!props.bgCoverImg}>
        {props.title && <Title>{props.title}</Title>}
        {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
        {props.children}
      </Wrapper>
    </Container>
  )
}

export default Card
