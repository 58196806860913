import ReactDOM from "react-dom"

interface Props {
  children: any
}

const Portal: React.FC<Props> = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}

export default Portal
