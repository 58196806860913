import { Button } from "components/buttons"
import format from "date-fns/format"
import ChecklistSvg from "images/checklist.inline.svg"
import { IEvent } from "models/IEvent"
import { ISymptomAnswer } from "models/ISymptomAnswer"
import React from "react"
import styled from "styled-components"
import DaySymptoms from "./DaySymptoms"
import CrossIcon from "images/cross.inline.svg"
import { breakpoints } from "utils"

const Container = styled.div`
  background: #ffffff;
  border-radius: 7px;
  padding: 16px 18px;
  z-index: 999;
  box-shadow: 0 0 10px 1px #eee;
`
const PopupPointer = styled.div`
  position: absolute;
  top: 220px;
  left: -6px;
  color: white;
  font-size: 16px;
  text-shadow: -4px 2px 6px #f1f1f1;
  transform: rotateZ(45deg);
`
const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: ${props => props.theme.colors.secondary};
`
const Subtitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
`
const NoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  margin-top: 96px;
  & p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #979797;
    max-width: 170px;
    margin-top: 12px;
  }
`
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const CloseBtn = styled(CrossIcon)`
  height: 24px;
  width: 24px;
  opacity: 0.6;
  display: none;
  @media (max-width: ${breakpoints.phone}px) {
    display: block;
  }
`
const ChecklistIcon = styled(ChecklistSvg)`
  fill: rgba(0, 0, 0, 0.15);
`
interface DayType {
  isFertileDay: boolean
  isMenstrualDay: boolean
}

export interface SymptomPopupProps {
  date: Date | null
  subtitle?: string
  style?: any
  popupLocation?: any
  dayType?: DayType
  eventGroup?: IEvent | null
  showFormModal?: Function
  hideFormModal?: Function
  formModalIsVisible?: boolean
  hasNoContent: boolean
  answers?: ISymptomAnswer[]
  onDeleteBtnClick?: Function
}

const SymptomsPopup: React.FC<SymptomPopupProps> = props => {
  return (
    <Container style={props.style} className="symptoms-popup">
      <>
        <PopupPointer>◣</PopupPointer>
        <HeaderWrapper>
          {props.date && (
            <Title>{format(props.date, "EEEE, LLL d, yyyy")}</Title>
          )}
          <CloseBtn onClick={props.hideFormModal} />
        </HeaderWrapper>
        {props.hasNoContent && (
          <NoContent>
            <ChecklistIcon />
            <p>
              Add your symptoms to keep track of how early menopause affects
              you.
            </p>
            <Button onClick={props.showFormModal}>Add symptoms</Button>
          </NoContent>
        )}
        {!props.hasNoContent && props.answers && (
          <DaySymptoms
            date={props.date}
            answers={props.answers || []}
            onEditBtnClick={props.showFormModal}
            onDeleteBtnClick={props.onDeleteBtnClick}
          />
        )}
      </>
    </Container>
  )
}

export default SymptomsPopup
