import { Button } from "components/buttons"
import MobileModal from "components/MobileModal"
import AddSvg from "images/plus-circle.inline.svg"
import _ from "lodash"
import { Amplify, Storage } from "aws-amplify"
import { AWS_CONFIG } from "../../../../aws-config"
import { IPrescription } from "models/IPrescription"
import React, { useState, useEffect } from "react"
import { Col, Form, InputGroup } from "react-bootstrap"
import styled from "styled-components"
import { breakpoints } from "utils"
import { FormControl, Label } from "../../Form"
import Modal from "../Modal"
import ModalContent from "../Modal/ModalContent"
import "./dnd-upload.scss"
import DndFileUpload from "./DndFileUpload"
import PrescriptionRow from "./PrescriptionRow"
import ErrorText from "components/ErrorText"
import PhoneNumberInput from "components/form/PhoneNumberInput"
import PleaseWaitButton from "components/form/PleaseWaitButton"
import { iFormError } from "models/IFormError"
import { FormModel } from "../DoctorsCard"

const Container = styled.div``
const AddMore = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  display: inline-block;
`
const AddIcon = styled(AddSvg)`
  stroke: ${props => props.theme.colors.primary};
  height: 18px;
`

enum ErrorType {
  fullName,
  specialization,
  emailAddress,
  phoneNumber,
  prescriptions,
  appointments,
}

interface Props {
  show: boolean
  onClose: Function
  onSubmit: Function
  error: iFormError
  loading: boolean
  formModel: FormModel
}

const DoctorFormModal: React.FC<Props> = props => {
  const [updating, setUpdating] = useState(false)
  const [form, setForm] = useState<FormModel>({
    report: null,
    fullName: "",
    specialization: "",
    emailAddress: "",
    phoneNumber: "",
    prescriptions: [
      {
        dailyFrequency: "",
        medicineName: "",
      },
    ],
  })
  const [prescriptions, setPrescriptions] = useState<IPrescription[]>([
    {
      dailyFrequency: "",
      medicineName: "",
    },
  ])
  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
  }, [])
  useEffect(() => {
    setForm(props.formModel)
    props.formModel.prescriptions.length &&
      setPrescriptions(props.formModel.prescriptions)
    if (props.formModel.id) {
      setUpdating(true)
    } else {
      setUpdating(false)
    }
  }, [props.formModel])

  const addMorePrescription = () => {
    setPrescriptions([
      ...prescriptions,
      {
        dailyFrequency: "",
        medicineName: "",
      },
    ])
  }

  const onPrescriptionUpdate = (
    index: number,
    update: Partial<IPrescription>
  ) => {
    setPrescriptions(
      _.map(prescriptions, (p, i) => {
        if (i === index) {
          if (
            update.dailyFrequency !== undefined &&
            update.dailyFrequency === "0"
          ) {
            return _.assign({}, p, { ...update, medicineName: "" })
          }
          return _.assign({}, p, update)
        }
        return p
      })
    )
  }

  const onFieldUpdate = (fieldName: string, value: any) => {
    setForm(
      _.assign({}, form, {
        [fieldName]: value,
      })
    )
  }

  const getFooter = () =>
    props.loading ? (
      <PleaseWaitButton />
    ) : (
      <Button onClick={onSubmit}>
        {updating ? "Update" : "Add"} Health professional
      </Button>
    )

  const onSubmit = async () => {
    props.onSubmit({
      ...form,
      prescriptions,
    })
  }

  const title = !updating
    ? "Add your Health professional"
    : "Update your Health professional"
  const subtitle =
    "Drag and drop your health professionals details here or add the details manually."

  const remove = (index: number) => {
    const newPrescriptions = [...prescriptions]
    _.remove(newPrescriptions, prescriptions[index])
    setPrescriptions([...newPrescriptions])
  }

  const getContent = () => {
    return (
      <ModalContent
        title={title}
        subtitle={subtitle}
        footer={getFooter()}
        onClose={props.onClose}
        bodyStyle={{ paddingTop: 0 }}
        containerStyle={{ padding: "0 10px" }}
      >
        <ErrorText text={props.error.text} />
        <DndFileUpload
          report={form.report}
          onSubmit={async (file: File) => {
            if (file) {
              const result: any = await Storage.put(file.name, file, {})
              onFieldUpdate("report", {
                bucket: AWS_CONFIG.Storage.AWSS3.bucket,
                region: AWS_CONFIG.Storage.AWSS3.region,
                key: result.key,
              })
            }
          }}
        />
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Label>Full Name</Label>
              <FormControl
                type="fullName"
                value={form.fullName}
                placeholder="E.g Kelly Smith"
                onChange={(e: any) => onFieldUpdate("fullName", e.target.value)}
                className={
                  props.error.type === ErrorType.fullName
                    ? "border-red-600 hover:border-red-700"
                    : ""
                }
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Label>Specialization</Label>
              <FormControl
                style={{ fontSize: "12px" }}
                value={form.specialization}
                placeholder="E.g Physician"
                onChange={(e: any) =>
                  onFieldUpdate("specialization", e.target.value)
                }
                className={
                  props.error.type === ErrorType.specialization
                    ? "border-red-600 hover:border-red-700"
                    : ""
                }
              ></FormControl>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Label>Email Address</Label>
              <FormControl
                type="emailAddress"
                value={form.emailAddress}
                placeholder="example@example.com"
                onChange={(e: any) =>
                  onFieldUpdate("emailAddress", e.target.value)
                }
                className={
                  props.error.type === ErrorType.emailAddress
                    ? "border-red-600 hover:border-red-700"
                    : ""
                }
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Label>Phone Number</Label>
              <InputGroup className="mb-1 mr-sm-1">
                <PhoneNumberInput
                  label=""
                  onChange={e => {
                    onFieldUpdate("phoneNumber", e)
                  }}
                  placeholder={"+61 (41) 2345 678"}
                  value={form.phoneNumber}
                  error={props.error.type === ErrorType.phoneNumber}
                  disable={false}
                  initCountry={"au"}
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          {prescriptions.map((v, i) => (
            <PrescriptionRow
              key={i.toString()}
              data={v}
              index={i}
              remove={remove}
              onChange={(update: Partial<IPrescription>) =>
                onPrescriptionUpdate(i, update)
              }
            />
          ))}
          <AddMore onClick={addMorePrescription}>
            <AddIcon /> Add more
          </AddMore>
        </Form>
      </ModalContent>
    )
  }

  const isPhoneView =
    typeof window !== `undefined` && window.innerWidth <= breakpoints.phone

  if (isPhoneView) {
    return <MobileModal show={props.show}>{getContent()}</MobileModal>
  }

  return (
    <Container>
      <Modal show={props.show} onClose={props.onClose}>
        {getContent()}
      </Modal>
    </Container>
  )
}

export default DoctorFormModal
