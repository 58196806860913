import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
`

interface Props {}

const DateCellWrapper: React.FC<Props> = props => {
  return <Container>{props.children}</Container>
}

export default DateCellWrapper
