import React from "react"
import styled from "styled-components"

const Container = styled.div`
  background: rgba(51, 51, 51, 0.05);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`
const Content = styled.div``
const Value = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: ${props => props.theme.colors.secondary};
`
const Label = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 4px;
`

interface Props {
  icon: React.ReactNode
  label: string
  value: string
  className?: string
}

const BmiMrtricCard: React.FC<Props> = props => {
  return (
    <Container className={props.className}>
      <Content>
        <Label>{props.label}</Label>
        <Value>{props.value}</Value>
      </Content>
      {props.icon}
    </Container>
  )
}

export default BmiMrtricCard
