import { Link } from "gatsby"
import React from "react"
import { PuffLoader } from "react-spinners"

import { FaBookOpen, FaRegStickyNote, FaTrashAlt } from "react-icons/fa"

interface iQPLCard {
  id: string
  text: string
  related_articles: (string | null)[]
  selected: boolean
  onClickDelete: (id: string) => void
  loading?: boolean
  onClickNote: (questionId: string) => void
  unsavedChanges: boolean
}

const DeleteButton: React.FC<{
  onClick: () => void
  title?: string
  Icon: any
  loading: boolean
}> = ({ onClick, Icon, title, loading }) => {
  return (
    <button
      onClick={() => {
        if (!loading) onClick()
      }}
      className="flex items-center text-center bg-gray-200 hover:bg-gray-300 text-sm text-red-800 px-3 py-2 rounded-full leading-none"
    >
      {loading ? (
        <PuffLoader size={16} color="red" />
      ) : (
        <Icon className={title && "inline"} />
      )}
      {title && <span className="ml-2 text-xs">{title}</span>}
    </button>
  )
}

const ReadMoreButton: React.FC<{
  relatedArticles: string[]
  hideText?: boolean
}> = ({ relatedArticles, hideText }) => {
  if (!relatedArticles.length) return <div />
  return (
    <div className="flex">
      <Link to={relatedArticles[0]} className="no-underline">
        <button className="flex items-center text-center text-xs text-white button-bg-site-pink  px-3 py-2 rounded-full leading-none">
          <FaBookOpen className={`inline ${!hideText && "mr-2"}`} />
          {!hideText && "Read more"}
        </button>
      </Link>
    </div>
  )
}

const TakeNotesButton: React.FC<{
  onClick: () => void
  unsavedChanges: boolean
}> = ({ onClick, unsavedChanges }) => {
  return (
    <button
      className="relative bg-site-blue button-bg-site-blue text-white px-3 py-2 rounded-full leading-none text-xs"
      onClick={onClick}
      title="Take notes"
    >
      <FaRegStickyNote className="inline" />
      {unsavedChanges && (
        <div
          className="top-0 right-0 absolute bg-yellow-500 rounded-full"
          style={{ height: "12px", width: "12px" }}
        />
      )}
    </button>
  )
}

const QPLCard: React.FC<iQPLCard> = ({
  id,
  text,
  related_articles,
  selected,
  onClickDelete,
  loading,
  onClickNote,
  unsavedChanges,
}) => {
  const _related_article = related_articles.filter(
    item => item != null
  ) as string[]
  return (
    <div>
      <div className="block sm:hidden px-4 py-4 bg-gray-100 site-border-radius text-site-blue-dark mx-2">
        <p>{text}</p>
        <div className="flex items-center justify-between mt-3">
          <DeleteButton
            onClick={() => onClickDelete(id)}
            Icon={FaTrashAlt}
            loading={Boolean(loading)}
            title={"Remove"}
          />
          <div className="flex items-center">
            <div className="ml-2">
              <ReadMoreButton
                relatedArticles={_related_article}
                hideText={true}
              />
            </div>
            <div className="ml-2">
              <TakeNotesButton
                onClick={() => onClickNote(id)}
                unsavedChanges={unsavedChanges}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:flex lg:hidden py-3 px-4 items-center justify-between bg-gray-100 site-border-radius mx-2 text-site-blue-dark">
        <div className="mr-2 flex-1">
          <p>{text}</p>
        </div>
        <div className="flex items-center w-48 justify-end">
          <DeleteButton
            onClick={() => onClickDelete(id)}
            Icon={FaTrashAlt}
            loading={Boolean(loading)}
          />
          <div className="ml-2">
            <ReadMoreButton
              relatedArticles={_related_article}
              hideText={true}
            />
          </div>
          <div className="ml-2">
            <TakeNotesButton
              onClick={() => onClickNote(id)}
              unsavedChanges={unsavedChanges}
            />
          </div>
        </div>
      </div>

      <div className="hidden lg:block pt-3 py-3 px-4 bg-gray-100 site-border-radius text-site-blue-dark">
        <p className="text-xs">{text}</p>
        <div className="flex items-center justify-between mt-3 flex-wrap">
          <div className="flex items-center ">
            <div className="mr-2">
              <ReadMoreButton relatedArticles={_related_article} />
            </div>
            <TakeNotesButton
              onClick={() => onClickNote(id)}
              unsavedChanges={unsavedChanges}
            />
          </div>
          <DeleteButton
            onClick={() => onClickDelete(id)}
            Icon={FaTrashAlt}
            loading={Boolean(loading)}
            title={"Remove"}
          />
        </div>
      </div>
    </div>
  )
}

export default QPLCard
