import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { FormControl } from "react-bootstrap"
import { RiDeleteBinLine } from "react-icons/ri"
import { IGoalStep, IGoalStepOption } from "models/GoalStep"
import theme from "components/layout/theme"
import CheckSvg from "images/check.inline.svg"
import PlusSvg from "images/plus-circle.inline.svg"
import trim from "lodash/trim"

const Container: any = styled.div`
  background: rgba(51, 51, 51, 0.05);
  border-radius: 16px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;
  position: relative;
  color: ${props => props.theme.colors.primary};
  ${(props: any) => {
    if (props.$active) {
      return css`
        background: ${theme.colors.blue};
        color: white;
      `
    }
  }}
`
const ErrorText = styled.span`
  position: absolute;
  font-size: 12px;
  top: 38px;
  line-height: 14px;
  white-space: pre-wrap;
`
const AddTagButton = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  display: inline-block;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
`
const CheckIcon = styled(CheckSvg)`
  fill: ${props => props.theme.colors.primary};
  margin-right: 6px;
  height: 10px;
  ${(props: any) => {
    if (props.$active) {
      return css`
        fill: white;
      `
    }
  }}
`
const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`
const Input = styled(FormControl)`
  font-size: 16px;
  border: 0;
  background: transparent;
  height: 24px;
  padding: 0;
`
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  & > input {
    color: ${theme.colors.primary};
  }
`
const PlusIcon = styled(PlusSvg)`
  margin-right: 2px;
  stroke: ${props => props.theme.colors.primary};
  height: 20px;
  & path {
    stroke-width: 2;
  }
`

const RemoveIcon = styled(RiDeleteBinLine)`
  margin-right: 6px;
`

interface Props {
  add: Function
  data: IGoalStep
  refId: string
}

const AddNewButton: React.FC<Props> = props => {
  const [text, setText] = useState("")
  const [refId, setRefId] = useState("")
  const [error, setError] = useState("")
  const [show, setShow] = useState(true)

  useEffect(() => {
    setShow(true)
    setText("")
    setError("")
    setRefId(props.refId)
  }, [props.data.title])
  const remove = () => {
    setShow(true)
    setText("")
    setError("")
    setRefId("")
  }
  const confirm = () => {
    if (trim(text) !== "") {
      setShow(true)
      setText("")
      setError("")
      props.add(text, refId)
      return
    }
    if (trim(text) === "") {
      setError("Option can't be empty string.")
    }
  }
  const onChange = (text: string) => {
    setText(text)
  }
  return (
    <Container>
      {show ? (
        <AddTagButton onClick={() => setShow(false)}>
          <PlusIcon />
          Add New
        </AddTagButton>
      ) : (
        <>
          <InputWrapper>
            <Input
              type="text"
              placeholder="I want to"
              value={text}
              id={refId}
              maxLength={100}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange(e.target.value)
              }
            />
            <RemoveIcon onClick={remove} />
          </InputWrapper>
          <CheckIcon onClick={confirm} />
          {error ? <ErrorText>{error}</ErrorText> : null}
        </>
      )}
    </Container>
  )
}

export default AddNewButton
