import { dateFnsLocalizer } from "react-big-calendar"
import enUS from "date-fns/locale/en-US"
import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import { formatRelative } from "date-fns"
import enGB from "date-fns/locale/en-GB"
import _ from "lodash"
import { bmiCategories } from "./BmiCard/constants"

export const getTimestamp = (ts: Date | number) => {
  const formatRelativeLocale: any = {
    lastWeek: "'Last' eeee HH:mm a",
    yesterday: "'Yesterday' HH:mm a",
    today: "'Today' HH:mm a",
    tomorrow: "'Tomorrow' HH:mm a",
    nextWeek: "'Next' eeee HH:mm a",
    other: "dd/MM/yyyy HH:mm a",
  }

  const locale = {
    ...enGB,
    formatRelative: (token: string) => formatRelativeLocale[token],
  }
  return formatRelative(ts, new Date(), { locale })
}

export const getGreeting = () => {
  const date = new Date()
  const h = date.getHours()
  if (h >= 0 && h < 12) {
    return "Good Morning"
  } else if (h >= 12 && h < 17) {
    return "Good Afternoon"
  }
  return "Good Evening"
}

export const getCalendarLocalizer = () =>
  dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales: { "en-US": enUS },
  })

// export const calculateBMI = (weight: number | null, height: number | null) => {
//   if (!weight || !height) {
//     return null
//   }
//   const numerator = weight
//   const heightInMeters = _.divide(height, 100)
//   const denominator = _.multiply(heightInMeters, heightInMeters)
//   return _.round(_.divide(numerator, denominator), 1)
// }
export const calculateBMI = (weight: number, height: number) =>
  (weight * 10000) / height / height

export const calculateBMICategory = (value: number) => {
  if (!value) return null
  if (isNaN(value)) return null
  if (value < bmiCategories.underweight.max) {
    return bmiCategories.underweight
  }
  if (
    value <= bmiCategories.healthyWeight.max &&
    value >= bmiCategories.underweight.max
  ) {
    return bmiCategories.healthyWeight
  }
  if (
    value <= bmiCategories.overweight.max &&
    value > bmiCategories.healthyWeight.max
  ) {
    return bmiCategories.overweight
  }
  if (value >= bmiCategories.obesity.min) {
    return bmiCategories.obesity
  }
  return null
}
