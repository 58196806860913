import React from "react"
import styled from "styled-components"
import ProgressBar from "components/ProgressBar"
import MedalSvg from "images/medal.inline.svg"
import { UserGoal } from "api/types"

const Container = styled.div`
  margin-top: 24px;
`
const Metric = styled.div`
  margin-bottom: 18px;
  cursor: pointer;
`
const MetricWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`
const MetricTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  flex: 1;
  && span {
    font-size: 12px;
    font-weight: 400;
  }
`
const MedalIcon = styled(MedalSvg)``

interface Props {
  goals: UserGoal[]
  setGoalReview: Function
}

const GoalCompleted: React.FC<Props> = ({ goals, setGoalReview }) => {
  return (
    <Container>
      {goals.length
        ? goals.map((goal, i) => (
            <Metric key={i.toString()} onClick={() => setGoalReview(goal)}>
              <MetricWrapper>
                <MetricTitle>
                  {goal.goal?.text}
                  {goal.goal?.ref && <span>/ {goal.goal?.ref}</span>}
                </MetricTitle>
                <div>
                  <MedalIcon />
                </div>
              </MetricWrapper>
              <ProgressBar percent={100} />
            </Metric>
          ))
        : null}
    </Container>
  )
}

export default GoalCompleted
