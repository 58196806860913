import React from "react"
import styled from "styled-components"
import CheckSvg from "images/check-circle.inline.svg"

const Container = styled.div`
  margin-bottom: 28px;
`
const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 12px;
`
const Inst = styled.div`
  margin-bottom: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.secondary};
  white-space: pre-wrap;
`
const CheckIcon = styled(CheckSvg)`
  margin-right: 8px;
`

interface Props {
  remarks: string[]
}

const BmiRemarks: React.FC<Props> = props => {
  return (
    <Container>
      <Title>Further Remarks</Title>
      {props.remarks.map((remark, i) => (
        <Inst key={i}>{remark}</Inst>
      ))}
    </Container>
  )
}

export default BmiRemarks
