import Carousel from "components/Carousel"
import { graphql, StaticQuery } from "gatsby"
import DiscoverCard from "components/DiscoverCard"
import ChevLeftSvg from "images/chevron-left.inline.svg"
import ChevRightSvg from "images/chevron-right-small.inline.svg"
import { IArticle } from "api/types"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { breakpoints } from "utils"
import Card from "./Card"
import { queryAllYaml } from "utils/staticQuery"
import { navigate } from "gatsby"
import Head from "./QPL/WithQPLView/Head"
import SaveCard from "./SaveCard"
import DashboardCarousel from "./dashBoardCarousel"

const query = graphql`
  query {
    bookmarkImage: file(relativePath: { eq: "bookmark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Container = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid lightgray;
  @media (min-width: ${breakpoints.phone}px) {
    background: none;

    & > div {
      padding: 0;
    }
  }
`

const Title = styled.div`
  color: ${props => props.theme.colors.white};
  @media (max-width: ${breakpoints.phone}px) {
    font-size: 18px;
    /* margin-bottom: 18px; */
  }
`
const NoArticles = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 14px;
`
const iconStyles = css`
  cursor: pointer;
  color: ${(props: any) => props.theme.colors.primary};
  stroke-width: 4;
  position: absolute;

  @media (max-width: ${breakpoints.tablet}px) {
    position: static;
  }
`
const LeftArrow = styled(ChevLeftSvg)`
  ${iconStyles};
  margin-right: 8px;
  right: 40px;
  top: -32px;
  height: 11px;
  @media (max-width: ${breakpoints.phone}px) {
    bottom: -132px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    height: 13px;
    top: 150px;
    left: 50;
  }
`
const RightArrow = styled(ChevRightSvg)`
  ${iconStyles};
  right: 10px;
  top: -36px;
  height: 19px;
  @media (max-width: ${breakpoints.phone}px) {
    height: 27px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    height: 25px;
  }
`

const ServiceCard = styled(SaveCard)`
  width: 100%;
  padding: 0px;
`

const renderCarouselItems = (items: IArticle[]) =>
  items.map(item => {
    return (
      <ServiceCard
        {...item}
        onClick={() => navigate(`/articles/${item.path}`)}
      />
    )
  })

interface Props {
  bookmarks: string[]
  data?: any
}

const ArticlesCard: React.FC<Props> = ({ bookmarks }) => {
  const { discoverArticles, manageArticles } = queryAllYaml()
  const [items, setItems] = useState<IArticle[]>([])
  useEffect(() => {
    const articleGroup = [
      ...discoverArticles.edges,
      ...manageArticles.edges,
    ].map(item => ({ ...item.node }))
    const bookmarkedArticles = articleGroup.filter(item =>
      bookmarks.includes(item.path)
    )
    setItems(bookmarkedArticles)
  }, [bookmarks])

  const title = <Title>My saved articles</Title>

  return (
    <>
      {items.length ? (
        <Container title={title}>
          <DashboardCarousel
            items={renderCarouselItems(items)}
            LeftArrowComp={LeftArrow}
            RightArrowComp={RightArrow}
            responsiveCfg={{ 0: { items: 1 } }}
          />
        </Container>
      ) : (
        <StaticQuery query={query}>
          {data => (
            <>
              <Head
                image={data.bookmarkImage.childImageSharp.fluid}
                title="Bookmarked Articles"
                description="You haven't bookmarked any articles, your bookmark articles will be shown here."
                buttonText="See all Articles"
                linkTo="discover"
              />

              <NoArticles></NoArticles>
            </>
          )}
        </StaticQuery>
      )}
    </>
  )
}

export default ArticlesCard
