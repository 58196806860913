import React from "react"
import styled from "styled-components"
import CheckSvg from "images/check-circle.inline.svg"

const Container = styled.div`
  margin-bottom: 28px;
`
const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 12px;
`
const Inst = styled.div`
  margin-bottom: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.secondary};
`
const CheckIcon = styled(CheckSvg)`
  margin-right: 8px;
  margin-bottom: 3px;
`

interface Props {
  recommendations: string[]
}

const items = [
  "Do at least 30min physical activity everyday.",
  "Consume less “bad” fat and more “good” fat.",
  "Consume less processed and sugary foods.",
  "A weight maintenance program should be a priority after the initial 6 months of weight-loss therapy.",
]

const BmiRecommends: React.FC<Props> = props => {
  return (
    <Container>
      <Title>Recommendations</Title>
      <Inst>
        If you wish to reduce your weight here are some things that have been
        proven to help.
      </Inst>
      {props.recommendations.map((item, i) => {
        return (
          <Inst key={i.toString()}>
            <CheckIcon />
            {item}
          </Inst>
        )
      })}
    </Container>
  )
}

export default BmiRecommends
