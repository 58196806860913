import { Button } from "components/buttons"
import CloseSvg from "images/close.inline.svg"
import { ISymptomQuestion } from "models/ISymptomQuestion"
import React from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"
import SymptomsForm from "./SymptomsForm"

const Header = styled(Modal.Header)`
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`
const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: ${props => props.theme.colors.secondary};
`
const Subtitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
`
const CloseBtn = styled(CloseSvg)`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`

interface Props {
  date: Date | null
  show?: boolean
  onClose?: Function
  onSubmit?: Function
  onOptionSelect: Function
  questions: ISymptomQuestion[]
}

const SymptomsModal: React.FC<Props> = props => {
  return (
    <Modal
      show={props.show}
      //  @ts-ignore
      onHide={props.onClose}
      size="lg"
      className="symptoms-modal"
    >
      <Header>
        <Title>Add your symptoms</Title>
        <Subtitle>
          Tell us how was your entire day experience overall by answering below
          questions.
        </Subtitle>
        <CloseBtn onClick={props.onClose} />
      </Header>
      <Modal.Body>
        <SymptomsForm
          questions={props.questions}
          addAnswer={props.onOptionSelect}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onSubmit}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SymptomsModal
