import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import { FaSearch } from "react-icons/fa"
import { QuestionPromptList_InternalLink } from "utils/urls"

interface iHead {
  onClickPrint?: () => void | null
  image: any
  title: string
  description?: string
  buttonText: string
  linkTo: string
}

const Head: React.FC<iHead> = ({
  image,
  buttonText,
  title,
  description,
  linkTo,
}) => {
  return (
    <div className="site-border-radius overflow-hidden relative">
      <Img fluid={image} style={{ height: "220px" }} />
      <div
        style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
        className={`absolute text-center text-white top-0
                        left-0 w-full h-full
                        flex items-center justify-center py-4 px-4 md:px-8 lg:px-4`}
      >
        <div>
          <h1 className="text-white text-lg mb-4">{title}</h1>
          <p className="text-white text-xs">{description}</p>
          <div className="flex items-center justify-center">
            <Link to={`/${linkTo}/`}>
              <button
                className={`text-white button-bg-site-blue bg-site-blue px-3 text-sm
                  leading-none py-2 rounded-full flex items-center`}
              >
                <span className="mr-2">
                  <FaSearch />
                </span>
                <span>{buttonText}</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Head
