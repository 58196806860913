const SYMPTOM_QUESTIONS = [
  {
    id: "zCLX6JpzA",
    title: "Hot flushes",
    name: "Hot Flushes",
    description:
      "Sudden feeling of warmth, usually most intense over the face, neck and chest, and profuse sweating.",
    iconSrc: "/symptioms-icon-fire.png",
    grayIconSrc: "/hot-flushes-gray.png",
    options: [
      {
        name: "No",
        value: "no",
        icon: "happy",
        color: "#00B73E",
        selected: false,
      },
      {
        name: "Mild",
        value: "mild",
        color: "#00B73E",
        selected: false,
        icon: "neutral",
      },
      {
        name: "Moderate",
        value: "moderate",
        color: "#F8A504",
        selected: false,
        icon: "confused",
      },
      {
        name: "Severe",
        value: "severe",
        color: "#F72B2B",
        selected: false,
        icon: "sad",
      },
      {
        name: "Very Severe",
        value: "verySevere",
        color: "#F72B2B",
        selected: false,
        icon: "cry",
      },
    ],
  },
  {
    id: "yvWfqxQoBP",
    title: "Sweating",
    name: "Sweats",
    iconSrc: "/symptioms-icon-drops.png",
    grayIconSrc: "/sweat-gray.png",
    description:
      "Sweats can happen during the day, but also at night leaving you and your sheets drenched.",
    options: [
      {
        name: "No",
        value: "no",
        icon: "happy",
        color: "#00B73E",
        selected: false,
      },
      {
        name: "Mild",
        value: "mild",
        color: "#00B73E",
        selected: false,
        icon: "neutral",
      },
      {
        name: "Moderate",
        value: "moderate",
        color: "#F8A504",
        selected: false,
        icon: "confused",
      },
      {
        name: "Severe",
        value: "severe",
        color: "#F72B2B",
        selected: false,
        icon: "sad",
      },
      {
        name: "Very Severe",
        value: "verySevere",
        color: "#F72B2B",
        selected: false,
        icon: "cry",
      },
    ],
  },
  {
    id: "nuhBgcunxV",
    title: "Sleep problems?",
    name: "Sleep Problems",
    iconSrc: "/symptioms-icon-pillow.png",
    grayIconSrc: "/vaginal-bleeding-gray.png",
    description:
      "Sleep problems can inlude disrupted sleep, difficulty getting to sleep or early morning wakening.",
    options: [
      {
        name: "No",
        value: "no",
        icon: "happy",
        color: "#00B73E",
        selected: false,
      },
      {
        name: "Yes",
        value: "yes",
        icon: "sad",
        color: "#F72B2B",
        selected: false,
      },
    ],
  },
  {
    id: "jJoz3tmNxY",
    title: "Thinking problems",
    name: "Thinking Problems",
    iconSrc: "/symptioms-icon-brain.png",
    grayIconSrc: "/thinking-problems-gray.png",
    description:
      "This can include poor memory, brain fog or unusual thoughts. Don't worry, this is also due to the fluctuations in estrogen and progesterone.",
    options: [
      {
        name: "No",
        value: "no",
        icon: "happy",
        color: "#00B73E",
        selected: false,
      },
      {
        name: "Yes",
        value: "yes",
        color: "#F72B2B",
        selected: false,
        icon: "sad",
      },
    ],
  },
  {
    id: "q_aVKWqG_q",
    title: "Mood",
    name: "Mood",
    iconSrc: "/symptioms-icon-chat.png",
    grayIconSrc: "/mood-gray.png",
    description:
      "Mood swings are normal during early menopause. Many women find themselves less tolerant and more easily annoyed.",
    options: [
      {
        name: "Happy",
        value: "happy",
        icon: "happy",
        color: "#00B73E",
        selected: false,
      },
      {
        name: "Neutral",
        value: "neutral",
        color: "#00B73E",
        selected: false,
        icon: "neutral",
      },
      {
        name: "Irritable",
        value: "irritable",
        color: "#F72B2B",
        selected: false,
        icon: "confused",
      },
      {
        name: "Sad",
        value: "sad",
        icon: "sad",
        color: "#F72B2B",
        selected: false,
      },
      {
        name: "Teary",
        value: "teary",
        icon: "cry",
        color: "#F72B2B",
        selected: false,
      },
      {
        name: "Anxious",
        value: "anxious",
        icon: "anxious",
        color: "#F72B2B",
        selected: false,
      },
    ],
  },
  {
    id: "Ngsbi9hwtY",
    title: "Sexual problems",
    name: "Sexual Problems",
    iconSrc: "/symptioms-icon-heartbreak.png",
    grayIconSrc: "/sexual-problems-gray.png",
    description:
      "You may feel moody or frustrated by all the changes and may feel less desire for sex.",
    options: [
      {
        name: "No",
        value: "no",
        icon: "happy",
        color: "#00B73E",
        selected: false,
      },
      {
        name: "Yes",
        value: "yes",
        icon: "sad",
        color: "#F72B2B",
        selected: false,
      },
    ],
  },
  {
    id: "t9okIABIKZ",
    title: "Vaginal dryness",
    name: "Vaginal Dryness",
    iconSrc: "/symptioms-icon-vagdry.png",
    grayIconSrc: "/vaginal-dryness-gray.png",
    description:
      "Lower levels of estrogen directly affect your vagina and can make the skin thinner, drier and less elastic.",
    options: [
      {
        name: "No",
        value: "no",
        icon: "happy",
        color: "#00B73E",
        selected: false,
      },
      {
        name: "Yes",
        value: "yes",
        icon: "sad",
        color: "#F72B2B",
        selected: false,
      },
    ],
  },
  {
    id: "G3Djw189Di",
    title: "Bleeding?",
    name: "Vaginal Bleeding",
    iconSrc: "/symptioms-icon-vagbleed.png",
    grayIconSrc: "/sleeping-problems-gray.png",
    description:
      "Bleeding can still happen during early menopause, but may be irregular.",
    options: [
      {
        name: "No",
        value: "no",
        icon: "happy",
        color: "#00B73E",
        selected: false,
      },
      {
        name: "Mild",
        value: "mild",
        color: "#00B73E",
        selected: false,
        icon: "neutral",
      },
      {
        name: "Moderate",
        value: "moderate",
        color: "#F8A504",
        selected: false,
        icon: "confused",
      },
      {
        name: "Severe",
        value: "severe",
        color: "#F72B2B",
        selected: false,
        icon: "sad",
      },
      {
        name: "Very Severe",
        value: "verySevere",
        color: "#F72B2B",
        selected: false,
        icon: "cry",
      },
    ],
  },
  {
    id: "B3ecNfqrcl",
    title: "Bladder problems",
    name: "Bladder Problems",
    iconSrc: "/symptioms-icon-bladder.png",
    grayIconSrc: "/bladder-problems-gray.png",
    description:
      "Bladder problems and weakness are a common issues during early menopause.",
    options: [
      {
        name: "No",
        value: "no",
        icon: "happy",
        color: "#00B73E",
        selected: false,
      },
      {
        name: "Yes",
        value: "yes",
        icon: "sad",
        color: "#F72B2B",
        selected: false,
      },
    ],
  },
]

export { SYMPTOM_QUESTIONS }
