import React from "react"
import { BsCheckCircle } from "../../../AppReactIcons"

const RecommendationClause: React.FC<{ text: string }> = ({ text }) => {
  return (
    <p className="flex items-start">
      <span className="mr-2 text-site-green">
        <BsCheckCircle className="inline" />
      </span>
      <span>{text}</span>
    </p>
  )
}

export default RecommendationClause
