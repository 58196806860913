import React, { useEffect, useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import { UserType } from "api/types"
import QPLNote from "components/QPLPage/QPLNote"
import { extractQuestionsFromQPLNodes } from "components/QPLPage/utils"
import { ContentSeparator } from "components/Utils/ContentSeparator"
import Footer from "./Footer"
import Head from "./Head"
import QPLCard from "./QPLCard"
import { QPLNode, QPLQuestion } from "api/types"
import styled from "styled-components"
import { breakpoints } from "../../../../utils"

interface iWithQPLView {
  userQPLList: (string | null)[]
  onClickDelete: (id: string) => void
  loadingList: string[]
  userProfile: UserType
}

const query = graphql`
  query {
    qplImage: file(relativePath: { eq: "qpl.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    qplQuestions: allQplYaml {
      nodes {
        id: categoryId
        categoryName
        questions {
          id: questionId
          qplCategoryId
          readMoreLink
          questionText
        }
      }
    }
  }
`

const Container = styled.div`
  @media (max-width: ${breakpoints.phone}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const WithQPLView: React.FC<iWithQPLView> = ({
  userQPLList,
  onClickDelete,
  loadingList,
  userProfile,
}) => {
  const INIT_MAX_QPL_LIST_LIMIT = 4
  const onClickPrint = () => {
    alert("Print service not available at the moment. Sorry")
  }
  const extractAllQuestions = (data: any) => {
    return extractQuestionsFromQPLNodes(data)
  }
  const [allQplNodes, setAllQplNodes] = useState<QPLNode[] | null>(null)
  const [qplListLimit, setQplListLimit] = useState(INIT_MAX_QPL_LIST_LIMIT)
  const [seeAllQuestions, setSeeAllQuestion] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [
    qplNotesList_unsavedChanges,
    set_qplNotesList_unsavedChanges,
  ] = useState<{ [id: string]: boolean }>({})
  const [takeNoteQuestion, setTakeNoteQuestion] = useState<QPLQuestion | null>(
    null
  )

  useEffect(() => {
    setSidebarOpen(true)
  }, [takeNoteQuestion])

  const onClickNote = (question: QPLQuestion) => {
    if (takeNoteQuestion && takeNoteQuestion.id === question.id)
      setSidebarOpen(true)
    else setTakeNoteQuestion(question)
  }

  return (
    <Container>
      <QPLNote
        sidebarOpen={sidebarOpen}
        onToggleSidebar={open => {
          setSidebarOpen(open)
        }}
        question={takeNoteQuestion}
        userProfile={userProfile}
        onChangeDeleteLoading={() => {}}
        onChangeUnsavedNotes={list => set_qplNotesList_unsavedChanges(list)}
        onChangeUpdateLoading={() => {}}
      />
      <StaticQuery query={query}>
        {data => (
          <div className="border site-border-radius mb-3">
            <Head
              image={data.qplImage.childImageSharp.fluid}
              onClickPrint={onClickPrint}
              title=" Questions I want to ask my doctor"
              buttonText="See all questions"
              linkTo="qpl"
            />

            <ContentSeparator />
            <div className="lg:hidden">
              <ContentSeparator />
            </div>
            <div className="px-2">
              <div className="grid grid-cols-1 gap-4 lg:gap-2">
                {extractAllQuestions(data.qplQuestions.nodes)
                  .filter(item => userQPLList.includes(item.id))
                  .splice(0, qplListLimit)
                  .map((item, index) => {
                    return (
                      <QPLCard
                        key={index}
                        id={item.id}
                        text={item.questionText || ""}
                        related_articles={item.readMoreLink || []}
                        selected={true}
                        onClickDelete={onClickDelete}
                        loading={loadingList.includes(item.id)}
                        onClickNote={questionId => {
                          onClickNote(item)
                        }}
                        unsavedChanges={qplNotesList_unsavedChanges[item.id]}
                      />
                    )
                  })}
              </div>
              <ContentSeparator />

              <Footer
                showMoreButton={
                  userQPLList.length > INIT_MAX_QPL_LIST_LIMIT &&
                  !seeAllQuestions
                }
                showLessButton={
                  userQPLList.length > INIT_MAX_QPL_LIST_LIMIT &&
                  seeAllQuestions
                }
                onClickShowMore={() => {
                  setQplListLimit(userQPLList.length)
                  setSeeAllQuestion(true)
                }}
                onClickShowLess={() => {
                  setQplListLimit(INIT_MAX_QPL_LIST_LIMIT)
                  setSeeAllQuestion(false)
                }}
                onClickPrint={onClickPrint}
              />
            </div>
            <ContentSeparator />
            <div className="lg:hidden">
              <ContentSeparator />
            </div>
          </div>
        )}
      </StaticQuery>
    </Container>
  )
}

export default WithQPLView
