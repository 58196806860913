import { Button } from "components/buttons"
import CalendarSvg from "images/calendar-1.inline.svg"
import EditSvg from "images/edit.inline.svg"
import RulerSvg from "images/ruler.inline.svg"
import WeightScaleSvg from "images/weight-scale.inline.svg"
import React, { useState, useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import styled, { css } from "styled-components"
import { breakpoints } from "utils"
import BmiBar from "./BmiBar"
import BmiMetricCard from "./BmiMetricCard"
import BmiModal from "./BmiModal"
import BmiRecommends from "./BmiRecommends"
import BmiRemarks from "./BmiRemarks"
import Card from "../Card"
import { apiPutPhysicalAttributes } from "api/queries"
import { navigate } from "gatsby"
import getProfileFromSessionStorage from "components/QueryUserProfile/getProfileFromSessionStorage"
import syncProfileWithSessionStorage from "components/QueryUserProfile/syncProfileWithSessionStorage"
import BMIChart from "./BMIChart"
import { calculateBMI } from "../utils"
import { UserType } from "../../../api/types"
import { NullValueNode } from "graphql-compose/lib/graphql"
import ContentCard from "./ContentCard"
import getBMIRemarks from "./getBMIRemarks"

const Container = styled(Card)`
  border: 1px solid lightgray;
`
const Wrapper = styled.div`
  margin-top: 12px;
`
const StyledButton = styled(Button)`
  width: 128px;
  font-size: 14px;
`
const Footer = styled.div`
  padding-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  & > button {
    margin-right: 12px;
  }
`

const Metrics = styled(Row)`
  margin-bottom: 18px;
  @media (min-width: ${breakpoints.phone}px) {
    & > div:nth-child(2) {
      padding: 0 6px;
    }
    & > div:nth-child(1) {
      padding-right: 12px;
    }
    & > div:nth-child(3) {
      padding-left: 12px;
    }
  }
`

const Value = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  color: ${props => props.theme.colors.blue};
  margin-bottom: 20px;
  & > span {
    margin-left: 6px;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.colors.secondary};
  }
`
const EditIcon = styled(EditSvg)`
  stroke: #ec4d7b;
  cursor: pointer;
`
const Edit = styled.div`
  text-align: right;
`
const iconStyles = css``
const CalendarIcon = styled(CalendarSvg)`
  ${iconStyles};
`
const WeightScaleIcon = styled(WeightScaleSvg)`
  ${iconStyles};
`
const RulerIcon = styled(RulerSvg)`
  ${iconStyles};
`
const BmiAgeMetricCard = styled(BmiMetricCard)`
  @media (max-width: ${breakpoints.phone}px) {
    margin-bottom: 12px;
    margin-right: 12px;
  }
`

interface IBmi {
  max?: number
  min?: number
  title: string
  category: string
  remarks: string[]
  recommendations?: string[]
}

interface Props {
  value: number | null
  bmi: IBmi | null
  catIndicator: string
  catPercent: string
  age: string
  dateOfBirth: string
  weight: number
  height: number
  update: Function
}

interface iBMICalculator {
  userProfile: UserType
}
const BmiCard: React.FC<Props> = props => {
  const MIN_BMI = 15.5
  const MAX_BMI = 42
  const weight = props.weight
  const height = props.height
  const BMI = calculateBMI(weight, height)
  const seekPositionInPercentage = (BMI - MIN_BMI) / (MAX_BMI - MIN_BMI)
  const { title, Recommendation } = getBMIRemarks(BMI)

  const [formIsVisible, setFormIsVisible] = useState<boolean>(false)
  const [data, setData] = useState({
    age: props.age,
    dateOfBirth: props.dateOfBirth,
    weight: props.weight,
    height: props.height,
  })

  const onFormClose = () => {
    setFormIsVisible(false)
    setData({
      age: props.age,
      dateOfBirth: props.dateOfBirth,
      weight: props.weight,
      height: props.height,
    })
  }
  const onFormSubmit = (form: any) => {
    const params = { ...form }
    apiPutPhysicalAttributes(params).then(data => {
      if (data) {
        setFormIsVisible(false)
        setData(form)
        const userData = JSON.parse(getProfileFromSessionStorage() as string)
        syncProfileWithSessionStorage({ ...userData, ...form })
        props.update({ ...userData, ...form })
      }
    })
  }

  const setGoal = () => {
    const btn =
      document.getElementById("add-goal") ||
      document.getElementById("add-goal-btn")
    console.log(btn)
    if (btn) {
      btn!.click()
    }
  }

  useEffect(() => {
    setData({
      age: props.age,
      dateOfBirth: props.dateOfBirth,
      weight: props.weight,
      height: props.height,
    })
  }, [props])

  return (
    <Container title="Check my weight">
      <Wrapper>
        {/* <Value>
          {props.value || " "}
          {props.value ? (
            <span>
              / <b>{props.bmi?.category}</b>{" "}
            </span>
          ) : (
            <span>
              <b>{props.bmi?.category}</b>{" "}
            </span>
          )}
        </Value> */}
        {/* <BmiBar min={0} max={30} value={props.value || 0} /> */}
        {/* <BMIChart BMI={BMI} progress={seekPositionInPercentage} /> */}
        <div className="grid gird-cols-1 sm:grid-cols-2 gap-7">
          <div className="">
            <BMIChart BMI={BMI} progress={seekPositionInPercentage} />
          </div>
          <div>
            <p style={{ fontSize: "14px" }} className=" text-gray-600 m-0">
              Maintaining a healthy weight range throughout your life is
              important but can be difficult at times. Weight gain during early
              menopause is common. This calculator will show your BMI index
              based on your details. You can use the recommendations to help you
              reach or stay within a healthy weight range.
            </p>
            <div className="grid grid-cols-1 gap-2 py-3">
              <Edit>
                <EditIcon onClick={() => setFormIsVisible(true)} />
              </Edit>
              <ContentCard title="Weight (kg)" content={weight} />
              <ContentCard title="Height (cm)" content={height} />
              <ContentCard
                noFlex={title.length > 5}
                title="Remarks"
                content={title}
              />
            </div>
          </div>
        </div>

        {/* <Metrics style={{ marginBottom: "18px" }} noGutters>
          <Col sm={"4"} xs="6">
            <BmiAgeMetricCard
              icon={<CalendarIcon />}
              label="Age"
              value={`${data.age || "-"} yrs`}
            />
          </Col>
          <Col sm={"4"} xs="6">
            <BmiMetricCard
              icon={<WeightScaleIcon />}
              label="Weight"
              value={`${data.weight || "-"} Kg`}
            />
          </Col>
          <Col sm={"4"}>
            <BmiMetricCard
              icon={<RulerIcon />}
              label="Height"
              value={`${data.height || "-"} cm`}
            />
          </Col>
        </Metrics> */}

        {props.bmi?.remarks && <BmiRemarks remarks={props.bmi?.remarks} />}
        {props.bmi?.recommendations && (
          <BmiRecommends recommendations={props.bmi?.recommendations} />
        )}
        <Footer>
          <StyledButton light onClick={() => navigate("/manage")}>
            View lifestyle
          </StyledButton>
          <StyledButton light onClick={setGoal}>
            Set a goal
          </StyledButton>
        </Footer>
      </Wrapper>
      <BmiModal
        data={data}
        show={formIsVisible}
        onClose={onFormClose}
        onSubmit={onFormSubmit}
      />
    </Container>
  )
}

export default BmiCard
