import { Button } from "components/buttons"
import { FormControl, Label } from "components/Form"
import MobileModal from "components/MobileModal"
import _ from "lodash"
import React, { useState, useEffect } from "react"
import { Col, Form } from "react-bootstrap"
import styled from "styled-components"
import { breakpoints } from "utils"
import Modal from "../Modal"
import ModalContent from "../Modal/ModalContent"
import ErrorText from "components/ErrorText"

const Container = styled.div``
const CustomModal = styled(Modal)`
  /* &.modal.fade:not(.in).bottom .modal-dialog { */
  /* transform: translate3d(0, 25%, 0) !important; */
  /* } */
  /* bottom: 0; */
`

interface Props {
  show: boolean
  onClose: Function
  onSubmit: Function
  data: any
}

const BmiModal: React.FC<Props> = props => {
  const [form, setForm] = useState(props.data)
  const [error, setError] = useState({
    text: "",
    type: "",
  })

  useEffect(() => {
    setForm(props.data)
    setError({ text: "", type: "" })
  }, [props.data])

  const onChange = (field: string, value: number) => {
    if (value.toString().length > 4) {
      return false
    }
    if (value <= 0) {
      setError({ text: `${field} is invalid.`, type: field })
    } else {
      setError({ text: "", type: "" })
    }
    setForm(
      _.assign({}, form, {
        [field]: value,
      })
    )
  }

  const onSubmit = () => {
    if (form.height <= 0) {
      setError({ text: `height is invalid.`, type: "height" })
      return
    }
    if (form.weight <= 0) {
      setError({ text: `weight is invalid.`, type: "weight" })
      return
    }
    props.onSubmit(form)
  }

  const onClose = () => {
    setError({ text: "", type: "" })
    props.onClose()
  }

  const getContent = () => {
    return (
      <ModalContent
        title="Body Mass Index"
        subtitle="Update your BMI metrics"
        footer={<Button onClick={() => onSubmit()}>Update</Button>}
        onClose={onClose}
        bodyStyle={{
          paddingTop: 0,
        }}
      >
        <ErrorText text={error.text} />
        <Form.Row>
          <Form.Group as={Col}>
            <Label>Weight</Label>
            <FormControl
              onChange={(e: any) => onChange("weight", e.target.value)}
              type="number"
              min={0}
              placeholder="in kg"
              value={form.weight}
              maxLength={4}
              className={
                error.type === "weight"
                  ? "border-red-600 hover:border-red-700"
                  : ""
              }
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Label>Height</Label>
            <FormControl
              onChange={(e: any) => onChange("height", e.target.value)}
              type="number"
              placeholder="in cms"
              value={form.height}
              maxLength={4}
              className={
                error.type === "height"
                  ? "border-red-600 hover:border-red-700"
                  : ""
              }
            />
          </Form.Group>
        </Form.Row>
      </ModalContent>
    )
  }

  const isPhoneView =
    typeof window !== `undefined` && window.innerWidth <= breakpoints.phone

  if (isPhoneView) {
    return <MobileModal show={props.show}>{getContent()}</MobileModal>
  }

  return (
    <Container>
      <CustomModal show={props.show} onClose={props.onClose}>
        {getContent()}
      </CustomModal>
    </Container>
  )
}

export default BmiModal
