import React from "react"
import styled, { css } from "styled-components"
import HappySvg from "images/happy.inline.svg"
import SadSvg from "images/sad.inline.svg"
import NeutralSvg from "images/neutral.inline.svg"
import ConfusedSvg from "images/confused.inline.svg"
import CrySvg from "images/cry.inline.svg"
import AnxiousSvg from "images/anxious.inline.svg"
import { ISymptomOption } from "models/ISymptomOption"
import { breakpoints } from "utils"

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 18px;
  @media (max-width: ${breakpoints.phone}px) {
    /* flex-direction: column; */
  }
`
const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 8px;
`
const Description = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 12px;
`
const Icon = styled.img`
  margin-right: 20px;
  @media (max-width: ${breakpoints.phone}px) {
    margin-right: 10px;
  }
`
const Content = styled.div``
const Options = styled.div`
  display: flex;
  align-items: center;
`
const SymptomOptionBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  &::first {
    align-items: flex-start;
  }
  & span {
    margin-top: 8px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 17px;
    color: ${props => props.theme.colors.secondary};
  }
  @media (max-width: ${breakpoints.phone}px) {
    margin-right: 15px;
  }
`

interface Props {
  id: string
  title: string
  iconSrc: string
  description: string
  options: ISymptomOption[]
  onOptionClick: Function
}

const OptionText: any = styled.span`
  ${(props: any) => {
    if (props.$selected) {
      return css`
        color: ${props.theme.colors.primary} !important;
      `
    }
  }};
`

const smileyIconStyles = css`
  stroke: rgba(0, 0, 0, 0.25);
  ${(props: any) => {
    if (props.$selected) {
      return css`
        stroke: ${props.$color || props.theme.colors.primary};
      `
    }
  }};
`
const HappyIcon = styled(HappySvg)`
  ${smileyIconStyles}
`
const SadIcon = styled(SadSvg)`
  ${smileyIconStyles}
`
const NeutralIcon = styled(NeutralSvg)`
  ${smileyIconStyles}
`
const ConfusedIcon = styled(ConfusedSvg)`
  ${smileyIconStyles}
`
const CryIcon = styled(CrySvg)`
  ${smileyIconStyles}
`
const AnxiousIcon = styled(AnxiousSvg)`
  ${smileyIconStyles}
`

const smileyIconMap = {
  happy: HappyIcon,
  sad: SadIcon,
  neutral: NeutralIcon,
  confused: ConfusedIcon,
  cry: CryIcon,
  anxious: AnxiousIcon,
}

const renderOptions = (options: ISymptomOption[], onOptionClick: Function) =>
  options.map((option, i) => {
    // @ts-ignore
    const SmileyIcon = smileyIconMap[option.icon]
    return (
      <SymptomOptionBtn
        onClick={() => onOptionClick(option)}
        key={i.toString()}
      >
        <SmileyIcon $selected={option.selected} $color={option.color} />
        <OptionText>{option.name}</OptionText>
      </SymptomOptionBtn>
    )
  })

const SymptomQuestion: React.FC<Props> = props => {
  const onOptionClick = (option: ISymptomOption) => {
    props.onOptionClick(props.id, option)
  }

  return (
    <Container>
      <Icon src={props.iconSrc} />
      <Content>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
        <Options>{renderOptions(props.options, onOptionClick)}</Options>
      </Content>
    </Container>
  )
}

export default SymptomQuestion
