export interface iAppButton {
  title?: any
  Icon?: object
  className?: string
  iconOnRight?: boolean
  [id: string]: any
  onClick?: () => void
}

export type registrationButtonWrapper_FuncType = (ButtonElement: object) => any

export type toggleSidebar_FuncType = (open: boolean) => void

export interface iArticleThumbnail {
  childImageSharp: {
    fluid: any
  }
}
export interface iArticleTranslation {
  language: string
  content: string
}

export interface iUsefulLink {
  title: string
  link: string
}

// export type iFeaturedMedia = string

export interface iArticleContentData {
  default: string
  translations: iArticleTranslation[]
}

export interface iArticleContent {
  title?: iArticleContentData
  heading?: iArticleContentData
  paragraph?: iArticleContentData
  bullets?: {
    default: string[]
    translations: {
      language: string
      content: string[]
    }[]
  }
}

export interface RelatedVideo {
  link: string
  title: string
}

export interface iArticleData {
  slug: string
  title: string
  description: string
  read_time: string
  last_updated_at: string
  related_articles: string[]
  previous_articles: string[]
  next_articles: string[]
  related_videos: RelatedVideo[]
  useful_links: iUsefulLink[]
  content: iArticleContent[]
  thumbnail: iArticleThumbnail
}

export interface iArticleTopicForPageContext {
  slug: string
  title: string
  image: any
  description: string
  slugPrefix: string
  Icon: any
}

export interface iRelatedArticle {
  node: iArticleData
}

export type Prescription = {
  dailyFrequency?: number
  medicineName?: string
}

export type Doctor = {
  id?: string
  fullName?: string | null
  specialization?: string | null
  emailAddress?: string | null
  phoneNumber?: string | null
  prescriptions?: Array<Prescription | null> | null
  appointments?: Array<string | null> | null
}

export type QuantitativeGoals = {
  dailyStepsGoal?: number | null
  weightLossGoal?: number | null
  dailyFruitIntakeGoal?: number | null
}

export type QplDataz = {
  id?: string
  selectedValue?: boolean | null
  notes?: string | null
}

export enum CalendarEventType {
  MENSURATION = "MENSURATION", // Indicates Mensuration Event
  OVULATION = "OVULATION", // Indicates Ovulation Event
  INTIMIACY = "INTIMIACY", // Indicates Intimacy Event
  FERTILE = "FERTILE", // Indicates Fertility Period
  MENSTRUAL = "MENSTRUAL", // Indicates Menstrual Period
}

export type UserCalendarEvent = {
  id?: string
  date?: string | null
  events?: CalendarEventType[]
}

export type CalendarSymptom = {
  id?: string
  symptomDate?: string
  symptomType?: SymptomType
  value?: SymptomChoice
}

export type SymptomType = {
  id?: string
  name?: string
  symptomQuestion?: string
  choices?: Array<SymptomChoice | null> | null
}

export type SymptomChoice = {
  id?: string
  icon?: string
  name?: string
  color?: string
}

export type UserCalendar = {
  events?: Array<UserCalendarEvent | null> | null
  symptoms?: Array<CalendarSymptom | null> | null
}

export interface iSidebarWrapper {
  children?: object
  sidebarOpen: boolean
  onToggleSidebar: toggleSidebar_FuncType
}
