const UnderWeightRemarks = [
  "You are underweight for your height. It's important to aim to keep within your healthy weight range. Being in a healthy weight range will improve your body's ability to fight off infection or illness.",
]
const HealthyWeightRemarks = [
  "You are a healthy weight for your height.\nAim to keep within the healthy weight range by enjoying a healthy, well-balanced diet and exercising regularly. Most adults should get at least 30 minutes of moderate-intensity physical activity on most, if not all, days.",
  "For older Australians, your general health may be more important than being mildly overweight. Some researchers have suggested that a BMI range of 22-26 is acceptable for older Australians over 70 years old.",
]
const OverWeightRemarks = [
  "Being in an unhealthy weight range increases your risk of developing coronary heart disease, as well as other health conditions. Keeping to a healthy weight will help you control your blood pressure and cholesterol levels.",
  "For individualised, medical and dietary advice consult your doctor and/or an Accredited Practising Dietitian.",
]
const ObesityRemarks = [
  "As your BMI increases so do your risk of developing coronary heart disease, as well as other health conditions. It is important that you take steps to reduce your weight. The good news is that even losing small amounts of weight can benefit your health. To achieve and maintain a healthy weight be physically active every day and eat a healthy, well-balanced diet that meets your energy needs.",
  "Talk to your Doctor. For individualised, medical and dietary advice consult your doctor and Accredited Practising Dietitian. If your BMI is over 35, your doctor may advise you on additional options available to support you with losing weight.",
]

const AboveRecommendations = [
  "Try and do at least 30 mins of activity on most days - you can break this up if you need to",
  "Try to work on making your lifestyle healthier and enlist the support of your health professionals if needed",
  "Cut down or remove foods and drinks that are high in sugar including biscuits, cakes, lollies, chocolate, soft drinks, energy drinks and juices",
  "Cut down on take away foods  and ideally cook your own, so you know what you are eating",
  "Avoid foods that are packaged - they are more likely to be processed and high in unhealthy fats and sugar. Substitute with vegetables and fruit",
  "See a health expert if you would like more guidance and support.",
]

export const bmiCategories = {
  underweight: {
    max: 18.5,
    category: "Underweight",
    remarks: UnderWeightRemarks,
  },
  healthyWeight: {
    max: 24.9,
    category: "Healthy weight",
    remarks: HealthyWeightRemarks,
  },
  overweight: {
    max: 29.9,
    category: "Above healthy weight",
    remarks: OverWeightRemarks,
    recommendations: AboveRecommendations,
  },
  obesity: {
    min: 30,
    category: "Significantly above a healthy weight",
    remarks: ObesityRemarks,
    recommendations: AboveRecommendations,
  },
}
