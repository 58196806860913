import React from "react"

const ContentCard: React.FC<{
  title: string
  content: string | number
  noFlex?: boolean
}> = ({ title, content, noFlex }) => {
  return (
    <div
      style={{ paddingTop: "16px", paddingBottom: "16px" }}
      className={`site-border-radius bg-gray-100 px-3 w-full flex justify-between `}
    >
      <p
        className={`text-xs font-bold text-gray-600 pr-2 mb-0 ${
          noFlex && "leading-none "
        }`}
      >
        {title}
      </p>
      <p
        className={`leading-none text-gray-600 flex flex items-center justify-between mb-0 ${
          noFlex ? "text-sm text-right" : "text-sm "
        }`}
      >
        {content}
      </p>
    </div>
  )
}

export default ContentCard
