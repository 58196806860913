import { H7 } from "components/typography"
import EditSvg from "images/edit.inline.svg"
import ShareSvg from "images/share.inline.svg"
import TrashSvg from "images/trash.inline.svg"
import { ISymptomAnswer } from "models/ISymptomAnswer"
import React from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"

const Container = styled.div``
const Title = styled(H7)`
  margin-top: 24px;
  margin-bottom: 12px;
  color: ${props => props.theme.colors.secondary};
`
const Section = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
`
const Icon = styled.img`
  margin-right: 10px;
  margin-top: 1px;
`
const Content = styled.div``
const Name = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
`
const Value = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
`
const Footer = styled.div`
  margin-top: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  padding-top: 16px;
`
const ShareIcon = styled(ShareSvg)`
  cursor: pointer;
  stroke: ${props => props.theme.colors.primary};
  height: 18px;
`
const EditIcon = styled(EditSvg)`
  margin-left: auto;
  cursor: pointer;
  stroke: ${props => props.theme.colors.primary};
  height: 18px;
`
const TrashIcon = styled(TrashSvg)`
  cursor: pointer;
  stroke: ${props => props.theme.colors.primary};
  height: 18px;
  margin-left: 12px;
`

interface Props {
  answers: ISymptomAnswer[]
  onEditBtnClick?: Function
  onDeleteBtnClick?: Function
  date: Date | null
}

const DaySymptoms: React.FC<Props> = props => {
  return (
    <Container>
      <Title>SYMPTOMS</Title>
      <Row>
        {props.answers && props.answers.length
          ? props.answers.map((answer, i) => {
              const { selectedOption } = answer
              return (
                <Col xs="6" key={i.toString()}>
                  <Section>
                    <Icon src={answer.iconSrc} />
                    <Content>
                      <Name>{answer.name}</Name>
                      {selectedOption && (
                        <Value style={{ color: selectedOption.color }}>
                          {selectedOption?.name}
                        </Value>
                      )}
                    </Content>
                  </Section>
                </Col>
              )
            })
          : null}
      </Row>
      <Footer>
        {/* <ShareIcon /> */}
        <EditIcon onClick={props.onEditBtnClick} />
        <TrashIcon
          onClick={() =>
            props?.onDeleteBtnClick && props?.onDeleteBtnClick(props.date)
          }
        />
      </Footer>
    </Container>
  )
}

export default DaySymptoms
