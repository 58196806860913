import React from "react"
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar"

const BMIChart: React.FC<{ BMI: number; progress: number }> = ({
  BMI,
  progress,
}) => {
  const isNan = isNaN(BMI)
  if (isNaN(progress)) {
    progress = 0
  }
  return (
    <div className=" w-60 mt-5 p-5">
      <CircularProgressbarWithChildren
        value={progress}
        maxValue={1}
        minValue={0}
        strokeWidth={7}
        styles={buildStyles({
          rotation: 0.75,
          pathTransitionDuration: 0.5,
          pathColor: "#EC4D7B",
          textColor: "#003b78",
          trailColor: "#E5E7EB",
        })}
      >
        <div className="mt-4">
          <h1
            style={{ fontSize: "40px" }}
            className=" text-site-blue-dark text-center m-0 "
          >
            {!isNan && BMI.toFixed(2)}
          </h1>
          {isNan ? (
            <h1 className="text-sm text-center text-gray-500 ">
              Please enter your
              <br /> weight and height
            </h1>
          ) : (
            <h1 className="text-sm text-center text-gray-500 ">
              Body Mass
              <br /> Index
            </h1>
          )}
        </div>
      </CircularProgressbarWithChildren>
    </div>
  )
}

export default BMIChart
