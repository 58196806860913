import { IPrescription } from "models/IPrescription"
import React, { useState, useEffect } from "react"
import { Col, Form } from "react-bootstrap"
import styled from "styled-components"
import { FormControl, Label } from "../../Form"
import { RiDeleteBinLine } from "react-icons/ri"
import theme from "components/layout/theme"

const Container = styled.div``
const RemoveIcon = styled(RiDeleteBinLine)`
  color: ${theme.colors.primary};
  cursor: pointer;
  top: 35px;
  position: absolute;
  right: -15px;
`
interface Props {
  data: IPrescription
  onChange: Function
  remove: Function
  index: number
}

const PrescriptionRow: React.FC<Props> = props => {
  const [disabled, setDisabled] = useState(false)
  const onChange = (value: any) => {
    if (value === 0) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
    props.onChange({ dailyFrequency: parseInt(value) })
  }
  useEffect(() => {
    if (props.data.dailyFrequency === "0") {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [props.data.dailyFrequency])
  return (
    <Container>
      <Form.Row>
        <Form.Group as={Col}>
          <Label>Medicine / Supplement</Label>
          <Form.Control
            style={{ fontSize: "12px" }}
            as="select"
            defaultValue=""
            value={props.data.dailyFrequency}
            placeholder="- Select - (Times/day)"
            onChange={e => onChange(e.target.value)}
          >
            <option value="" disabled hidden>
              - Select - (Times/day)
            </option>
            <option value="0">No Medicine / Supplement</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Label>Medicine Name</Label>
          <FormControl
            type="text"
            disabled={disabled}
            placeholder="Medicine Name"
            value={props.data.medicineName}
            onChange={(e: any) =>
              props.onChange({ medicineName: e.target.value })
            }
          />
          {props.index ? (
            <RemoveIcon onClick={() => props.remove(props.index)} />
          ) : (
            ""
          )}
        </Form.Group>
      </Form.Row>
    </Container>
  )
}

export default PrescriptionRow
