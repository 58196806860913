import format from "date-fns/format"
import React from "react"
import { HeaderProps } from "react-big-calendar"
import styled from "styled-components"
import { breakpoints } from "utils"

const Container = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 10px;
`

const getDay = (date: Date) =>
  typeof window !== `undefined` && window.innerWidth <= breakpoints.phone
    ? format(date, "EEEEE")
    : format(date, "EEE")

const MonthHeader: React.FC<HeaderProps> = props => {
  return <Container>{getDay(props.date)}</Container>
}

export default MonthHeader
