import React, { useState } from "react"
import { FaEye, FaEyeSlash } from "../../../AppReactIcons"

import RecommendationClause from "./RecommendationClause"

const getBMIRemarks = (BMI: number) => {
  if (isNaN(BMI))
    return {
      title: "No remarks to display.",
    }
  if (BMI < 18.5)
    return {
      title: "Underweight",
      Recommendation: () => (
        <div className="text-sm">
          <div className="flex items-center mb-2  justify-between">
            <h1 className="text-gray-600 text-sm">Further remarks</h1>
          </div>
          <p>
            You are underweight for your height. It's important to aim to keep
            within your healthy weight range. Being in a healthy weight range
            will improve your body's ability to fight off infection or illness.
          </p>
        </div>
      ),
    }
  if (BMI < 24.9)
    return {
      title: "Healthy",
      Recommendation: () => (
        <div className="text-sm">
          <div className="flex items-center mb-2  justify-between">
            <h1 className="text-gray-600 text-sm">Further remarks</h1>
          </div>
          <p>You are a healthy weight for your height.</p>
          <br />
          <p>
            Aim to keep within the healthy weight range by enjoying a healthy,
            well-balanced diet and exercising regularly. Most adults should get
            at least 30 minutes of moderate-intensity physical activity on most,
            if not all, days..
          </p>
          <br />
          <p>
            For older Australians, your general health may be more important
            than being mildly overweight. Some researchers have suggested that a
            BMI range of 22-26 is acceptable for older Australians over 70 years
            old.
          </p>
        </div>
      ),
    }
  if (BMI < 29.9)
    return {
      title: "Above healthy weight",
      Recommendation: () => {
        const [furtherRemarkVisible, setFurtherRemarksVisible] = useState(false)

        return (
          <div className="text-sm">
            <div className="flex items-center mb-2 border-b pb-2 justify-between">
              <h1 className="text-gray-600 text-sm">Further remarks</h1>
              <button
                title="See more"
                onClick={() => setFurtherRemarksVisible(!furtherRemarkVisible)}
                className="text-gray-600"
              >
                {!furtherRemarkVisible && <FaEye />}
                {furtherRemarkVisible && <FaEyeSlash />}
              </button>
            </div>
            {furtherRemarkVisible && (
              <>
                <p>
                  Being in an unhealthy weight range increases your risk of
                  developing coronary heart disease, as well as other health
                  conditions. Keeping to a healthy weight will help you control
                  your blood pressure and cholesterol levels..
                </p>
                <p>
                  For individualised, medical and dietary advice consult your
                  doctor and/or an Accredited Practising Dietitian..
                </p>
                <button
                  className="text-blue-600 cursor-pointer"
                  onClick={() =>
                    setFurtherRemarksVisible(!furtherRemarkVisible)
                  }
                >
                  Read less
                </button>
                <br />
                <br />
              </>
            )}
            {!furtherRemarkVisible && (
              <>
                <p className="text-gray-600">
                  Being in an unhealthy weight range increases your risk of
                  developing coronary heart disease...
                  <span
                    className="text-blue-700 cursor-pointer"
                    onClick={() =>
                      setFurtherRemarksVisible(!furtherRemarkVisible)
                    }
                  >
                    &nbsp;Read more
                  </span>
                </p>
                <br />
              </>
            )}
            <h1 className="text-gray-600 mb-2 text-sm">Recommendations</h1>
            <RecommendationClause text="Try and do at least 30 mins of activity on most days - you can break this up if you need to" />
            <RecommendationClause text="Try to work on making your lifestyle healthier and enlist the support of your health professionals if needed" />
            <RecommendationClause text="Cut down or remove foods and drinks that are high in sugar including biscuits, cakes, lollies, chocolate, soft drinks, energy drinks and juices" />
            <RecommendationClause text="Cut down on take away foods  and ideally cook your own, so you know what you are eating" />
            <RecommendationClause text="Avoid foods that are packaged - they are more likely to be processed and high in unhealthy fats and sugar. Substitute with vegetables and fruit" />
            <RecommendationClause text="See a health expert if you would like more guidance and support." />
          </div>
        )
      },
    }
  return {
    title: "Significantly above a healthy weight",
    Recommendation: () => {
      const [furtherRemarkVisible, setFurtherRemarksVisible] = useState(false)

      return (
        <div className="text-sm">
          <div className="flex items-center mb-2 border-b pb-2 justify-between">
            <h1 className="text-gray-600 text-sm">Further remarks</h1>
            <button
              title="See more"
              onClick={() => setFurtherRemarksVisible(!furtherRemarkVisible)}
              className="text-gray-600"
            >
              {!furtherRemarkVisible && <FaEye />}
              {furtherRemarkVisible && <FaEyeSlash />}
            </button>
          </div>
          {furtherRemarkVisible && (
            <>
              <p>
                As your BMI increases so do your risk of developing coronary
                heart disease, as well as other health conditions. It is
                important that you take steps to reduce your weight. The good
                news is that even losing small amounts of weight can benefit
                your health. To achieve and maintain a healthy weight be
                physically active every day and eat a healthy, well-balanced
                diet that meets your energy needs.
              </p>
              <br />
              <p>
                Talk to your Doctor. For individualised, medical and dietary
                advice consult your doctor and Accredited Practising Dietitian.
                If your BMI is over 35, your doctor may advise you on additional
                options available to support you with losing weight.
              </p>
              <button
                className="text-blue-600 cursor-pointer"
                onClick={() => setFurtherRemarksVisible(!furtherRemarkVisible)}
              >
                Read less
              </button>
              <br />
              <br />
            </>
          )}
          {!furtherRemarkVisible && (
            <>
              <p className="text-gray-600">
                As your BMI increases so does your risk of developing coronary
                heart disease...
                <span
                  className="text-blue-700 cursor-pointer"
                  onClick={() =>
                    setFurtherRemarksVisible(!furtherRemarkVisible)
                  }
                >
                  &nbsp;Read more
                </span>
              </p>
              <br />
            </>
          )}
          <h1 className="text-gray-600 mb-2 text-sm">Recommendations</h1>

          <RecommendationClause text="Try and do at least 30 mins of activity on most days - you can break this up if you need to" />
          <RecommendationClause text="Try to work on making your lifestyle healthier and enlist the support of your health professionals if needed" />
          <RecommendationClause text="Cut down or remove foods and drinks that are high in sugar including biscuits, cakes, lollies, chocolate, soft drinks, energy drinks and juices" />
          <RecommendationClause text="Cut down on take away foods  and ideally cook your own, so you know what you are eating" />
          <RecommendationClause text="Avoid foods that are packaged - they are more likely to be processed and high in unhealthy fats and sugar. Substitute with vegetables and fruit" />
          <RecommendationClause text="See a health expert if you would like more guidance and support." />
        </div>
      )
    },
  }
}

export default getBMIRemarks
