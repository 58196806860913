import { Button } from "components/buttons"
import AddSvg from "images/plus-circle.inline.svg"
import ReportSvg from "images/report.inline.svg"
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"
import { IADoctor } from "models/IDoctor"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import Card from "./Card"
import ChevLeftSvg from "images/chevron-left.inline.svg"
import ChevRightSvg from "images/chevron-right-small.inline.svg"
import DoctorDetails from "./DoctorDetails"
import DoctorFormModal from "./DoctorFormModal"
import { apiPutDoctors } from "api/queries"
import syncProfileWithSessionStorage from "components/QueryUserProfile/syncProfileWithSessionStorage"
import getProfileFromSessionStorage from "components/QueryUserProfile/getProfileFromSessionStorage"
import { UserType } from "api/types"
import { iFormError } from "models/IFormError"
import { validateEmail } from "components/profilePage/utils"
import { toast } from "react-toastify"
import toastError from "utils/toastError"
import { IPrescription } from "models/IPrescription"
import Carousel from "../Carousel"
import { breakpoints } from "utils"
import "react-alice-carousel/lib/alice-carousel.css"

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const AddIcon = styled(AddSvg)`
  cursor: pointer;
  stroke: ${(props: any) => props.theme.colors.white};
  height: 18px;
`

const Container = styled(Card)`
  overflow: inherit;
  border: 1px solid lightgray;
  @media (max-width: ${breakpoints.phone}px) {
    border-radius: 12px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    border-radius: 12px;
  }
`
const NotFound = styled.div`
  margin-top: 64px;
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ReportIcon = styled(ReportSvg)`
  fill: rgba(0, 0, 0, 0.15);
  height: 59px;
  width: 59px;
`
const InfoText = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${props => props.theme.colors.secondary};
  padding: 0 36px;
`
const iconStyles = css`
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  stroke-width: 4;
  position: absolute;
`
const LeftArrow = styled(ChevLeftSvg)`
  ${iconStyles};
  margin-right: 8px;
  right: 52px;
  top: -16px;
  height: 11px;
  @media (max-width: ${breakpoints.phone}px) {
    height: 15px;
  }
`
const RightArrow = styled(ChevRightSvg)`
  ${iconStyles};
  right: 30px;
  top: -20px;
  height: 19px;
  @media (max-width: ${breakpoints.phone}px) {
    height: 27px;
  }
`

interface Props {
  doctors: IADoctor[] | null
  userProfile: UserType | null
}
export interface FormModel {
  id?: string
  fullName: string
  specialization: string
  emailAddress: string
  phoneNumber: string
  prescriptions: IPrescription[]
  report?: any
  // appointments:,
}
enum ErrorType {
  fullName,
  specialization,
  emailAddress,
  phoneNumber,
  prescriptions,
  appointments,
}
const DoctorsCard: React.FC<Props> = props => {
  const [formIsVisible, setFormIsVisible] = useState<boolean>(false)
  const [formModel, setFormModel] = useState<FormModel>({
    report: null,
    fullName: "",
    specialization: "",
    emailAddress: "",
    phoneNumber: "",
    prescriptions: [
      {
        dailyFrequency: "",
        medicineName: "",
      },
    ],
  })
  const [doctors, setDoctors] = useState<IADoctor[] | null>([])
  const [error, setError] = useState<iFormError>({
    text: null,
    type: null,
  })
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)

  useEffect(() => {
    setDoctors(props.doctors)
  }, [props.doctors])
  const updateDoctor = (data: FormModel) => {
    setFormModel(data)
    setFormIsVisible(true)
  }
  const onFormClose = () => {
    setFormIsVisible(false)
    setError({
      text: null,
      type: null,
    })
    setFormModel({
      report: null,
      fullName: "",
      specialization: "",
      emailAddress: "",
      phoneNumber: "",
      prescriptions: [
        {
          dailyFrequency: "",
          medicineName: "",
        },
      ],
    })
  }
  const renderCarouselItems = (items: IADoctor[]) =>
    items.map((item, i) => (
      <DoctorDetails
        key={i.toString()}
        deleting={deleting}
        data={item}
        updateDoctor={updateDoctor}
        removeDoctor={remove}
      />
    ))
  const validateInput = ({
    id,
    fullName,
    specialization,
    emailAddress,
    phoneNumber,
    prescriptions,
    appointments,
  }: {
    id: string
    fullName: string
    specialization: string
    emailAddress: string
    phoneNumber: string
    prescriptions: []
    appointments: []
  }) => {
    if (!fullName)
      return {
        text: "Please, enter a full name",
        type: ErrorType.fullName,
      }
    if (!specialization)
      return {
        text: "Please, enter specialization",
        type: ErrorType.specialization,
      }
    if (!validateEmail(emailAddress))
      return {
        text: "Email address is not valid",
        type: ErrorType.emailAddress,
      }
    if (!phoneNumber || phoneNumber === "+")
      return {
        text: "Please, enter a mobile number",
        type: ErrorType.phoneNumber,
      }
    return null
  }
  const putDoctors = (params: any[]) => {
    apiPutDoctors(params)
      .then(data => {
        if (data && data.doctors) {
          let savedUserData = JSON.parse(getProfileFromSessionStorage() || "")
          savedUserData.doctors = data.doctors
          setDoctors(data.doctors as IADoctor[])
          syncProfileWithSessionStorage({
            ...savedUserData,
            doctors: data.doctors,
          })
        }
        onFormClose()
        setLoading(false)
        setDeleting(false)
        setError({
          text: null,
          type: null,
        })
        setFormModel({
          fullName: "",
          specialization: "",
          emailAddress: "",
          phoneNumber: "",
          prescriptions: [
            {
              dailyFrequency: "",
              medicineName: "",
            },
          ],
        })
      })
      .catch(e => {
        // TODO
        console.error(e)
        const errorField = e.errors[0].message.split(" ")[1].replace(/\'/g, "")
        const errorMsg = `${_.lowerCase(errorField)} has an invalid value.`
        if (ErrorType[errorField] !== undefined) {
          setError({
            text: errorMsg,
            type: ErrorType[errorField],
          })
        } else {
          toastError(toast, errorField)
        }
        setLoading(false)
        setDeleting(false)
      })
  }
  const remove = (data: FormModel) => {
    const params = doctors?.filter(item => item.id !== data.id) as any[]
    setDeleting(true)
    putDoctors(params)
  }
  const onSubmit = (data: any) => {
    setError({
      text: null,
      type: null,
    })
    const err = validateInput(data)
    if (err) {
      setError(err)
      return
    }
    data.prescriptions = data.prescriptions.filter(
      (item: any) => item.dailyFrequency !== ""
    )
    setLoading(true)
    const isCreate = !data.id
    let params: any[] = []
    if (!isCreate) {
      if (doctors && doctors.length) {
        params = [...doctors].map(item => {
          if (item.id === data.id) {
            return data
          }
          return item
        })
      } else {
        params = [data]
      }
    } else {
      params = [{ ...data, id: uuidv4() }, ...(doctors || [])]
    }
    putDoctors(params)
  }

  const getTitle = () => (
    <Header>
      My health professionals
      {doctors && (
        <div>
          <AddIcon onClick={() => setFormIsVisible(true)} />
        </div>
      )}
    </Header>
  )

  return (
    <Container title={getTitle()}>
      {!doctors || !doctors.length ? (
        <NotFound>
          <ReportIcon />
          <InfoText>Add your health professionals' details here.</InfoText>
          <Button onClick={() => setFormIsVisible(true)}>
            Add Health professionals
          </Button>
        </NotFound>
      ) : doctors.length > 1 ? (
        <Carousel
          items={renderCarouselItems(doctors)}
          LeftArrowComp={LeftArrow}
          RightArrowComp={RightArrow}
          responsiveCfg={{ 0: { items: 1 } }}
          autoHeight={true}
        />
      ) : (
        <DoctorDetails
          deleting={deleting}
          data={doctors[0]}
          updateDoctor={updateDoctor}
          removeDoctor={remove}
        />
      )}
      <DoctorFormModal
        error={error}
        formModel={formModel}
        show={formIsVisible}
        onClose={onFormClose}
        onSubmit={onSubmit}
        loading={loading}
      />
    </Container>
  )
}

export default DoctorsCard
