/**
 * Goal steps config
 */

export const GoalSteps = [
  {
    description: "Think of small, achievable steps that are within your reach.",
    icon: "/goals.png",
    id: "goal",
    options: [
      {
        text: "My weight",
        ref: "Diet",
        tagIcon: "weight.inline.svg",
      },
      {
        text: "Be more active",
        ref: "Exercise",
        tagIcon: "/exercise1.inline.svg",
      },
      {
        text: "Less high fat and high sugar foods and drinks",
        ref: "Diet",
        tagIcon: "/highfat.inline.svg",
      },
      {
        text: "Spend more time relaxing",
        ref: "Wellbeing",
        tagIcon: "/yogareal.svg",
      },
      {
        text: "Making my health a priority",
        ref: "Wellbeing",
        tagIcon: "/healths.inline.svg",
      },
      {
        text: "Eat more vegetables",
        ref: "Diet",
        tagIcon: "/leave.inline.svg",
      },
      {
        text: "Drink more water",
        ref: "Diet",
        tagIcon: "/water.inline.svg",
      },
      {
        text: "Eat more fruit",
        ref: "Diet",
        tagIcon: "/fruits.inline.svg",
      },
      {
        text: "Limiting temptations for unhealthy food",
        ref: "Diet",
        tagIcon: "/nofast.svg",
      },
      {
        text: "Less snacking",
        ref: "Diet",
        tagIcon: "/pizza.svg",
      },
      {
        text: "Drink less soft drinks or sugary drinks",
        ref: "Diet",
        tagIcon: "/nosoft.svg",
      },
      {
        text: "Eat more home cooked meals",
        ref: "Diet",
        tagIcon: "/cook.inline.svg",
      },
      {
        text: "Reduce alcohol consumption",
        ref: "Wellbeing",
        tagIcon: "/wine.inline.svg",
      },
      {
        text: "Stop smoking",
        ref: "Wellbeing",
        tagIcon: "/nosmoke1.svg",
      },
    ],
    title: "Pick your goals",
  },
  {
    description:
      "Try to be clear about what is important to you to change and why.",
    icon: "/why.png",
    id: "motivations",
    options: [
      {
        text: "Feel better",
      },
      {
        text: "Manage my symptoms",
      },
      {
        text: "Improve hot flushes",
      },
      {
        text: "Prevent health issues later",
      },
      {
        text: "Manage my health",
      },
    ],
    title: "List what motivates you to change",
  },
  {
    description:
      "Obstacles are things or thoughts that stop you from achieving your goal. Obstacles are very individual – what may stop you?",
    icon: "/obstacle.png",
    id: "obstacles",
    options: [
      {
        text: "I don't have enough time.",
      },
      {
        text: "I just don't feel motivated.",
      },
      {
        text: "I am too tired.",
      },
      {
        text: "I don't like getting up early.",
      },
      {
        text: "It's too hot or cold outside.",
      },
      {
        text:
          "I don't have any information or don't know where to go for information.",
      },
      {
        text: "I don't feel confident.",
      },
      {
        text: "I don't have anyone to join me.",
      },
      {
        text:
          "Partner or kids complain when healthy changes are suggested or made.",
      },
      {
        text: "I don't have the right resources or equipment.",
      },
    ],
    title: "List of obstacles",
  },
  {
    description:
      "Strategies are actions that will help you address your barriers. List your own strategies that will help you overcome the obstacles you’ve identified in the step above.",
    icon: "/chess.png",
    id: "strategies",
    options: [
      {
        text: "Get up 20 minutes earlier 1 day each week",
      },
      {
        text: "Walk kids to school",
      },
      {
        text: "Walk on weekends when you have more time",
      },
      {
        text:
          "Instead of 1 large shop each week do 2 smaller shops so fresh food is available all week",
      },
      {
        text: "Ask a friend to go for a walk with you",
      },
      {
        text:
          "Use walking as main transport to get around on weekends instead of driving as much",
      },
      {
        text: "Go to bed half an hour earlier during the week",
      },
      {
        text:
          "If you run out of fresh vegetables make sure you have some frozen or canned",
      },
      {
        text: "Make extra meals and freeze leftovers for mid-week ideas",
      },
      {
        text:
          "Ask your partner to help run the kids to activities on the weekend so you have time to cook",
      },
      {
        text:
          "Listen to music or read articles on your phone to pass the time quickly while exercising",
      },
      {
        text: "Put out fresh fruit so they are easy to see and grab",
      },
    ],
    title: "What are your strategies?",
  },
  {
    description:
      "Be specific, combine all the steps above into one overall goal. Summarise your strategies to make it achievable to you.",
    icon: "/calendar.png",
    id: "executions",
    title: "When / Where / How often",
    customText: "",
  },
]

/**
 * Home page cover section config
 */
export const HomepageConfig = {
  paragraph:
    "Menopause in women younger than 40 years of age is called premature menopause. Early Menopause affects about 1 in 100 women. Around 8 per 100 women have premature menopause due to other causes such as chemotherapy or surgery. On our app, you can read, watch and listen to stories of women aged between 28 and 51 years, from diverse socio-cultural backgrounds, who have shared what it is like to experience early menopause (EM). Women talked about being diagnosed with spontaneous early menopause, premature ovarian insufficiency (POI), medically-induced early menopause, or menopausal symptoms as a result of cancer and/or other medical treatments. You will find stories about diagnosis, symptoms, long-term health effects, treatments, experiences with health services and health practitioners, and the impact of early menopause on women’s personal lives and relationships are just some of the many aspects of the experience of early menopause.",
  link: "/discover",
}
