import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { FormCheck } from "react-bootstrap"
import Modal from "../../Modal"
import ModalContent from "../../Modal/ModalContent"
import { breakpoints } from "utils"
import { Button } from "components/buttons"
import theme from "components/layout/theme"
import Slider, { createSliderWithTooltip } from "rc-slider"
import "rc-slider/assets/index.css"
import { UserGoal } from "api/types"
import { HiPencil } from "react-icons/hi"

const SliderWithTooltip = createSliderWithTooltip(Slider)
function percentFormatter(v: any) {
  return `${v} %`
}

const CustomModal = styled(Modal)`
  @media (min-width: ${breakpoints.desktop}px) {
    max-width: 650px;
  }
  @media (max-width: ${breakpoints.phone}px) {
    // margin-left: -4px;
  }
`
const Container = styled.div`
  display: flex;
  align-items: flex-start;
`
const Icon = styled.img`
  margin-right: 20px;
`
const Content = styled.div`
  width: 100%;
`
const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 8px;
`
const Check = styled(FormCheck)`
  margin-left: 5px;
  margin-right: 0;
  cursor: pointer;
`

const Options = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  position: relative;
`
const StyledEditIcon = styled(HiPencil)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  color: ${theme.colors.primary};
`
const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
`
const SliderWrapper = styled.div`
  flex: 1;
  margin-left: 7px;
  margin-right: 7px;
`
const SliderContainer = styled.div`
  @media (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }
  width: calc(100% - 140px);
  display: flex;
  align-items: center;
`
const Prefix = styled.span``
const Suffix = styled.span``
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`
const ActionButtons = styled.div`
  & button:nth-child(1) {
    margin-right: 16px;
  }
`
const Text = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.secondary};
  user-select: none;
`
interface Props {
  show: boolean
  goal: UserGoal | null
  onSubmit: Function
  onClose: Function
}

const GoalReview: React.FC<Props> = props => {
  const { goal, onSubmit } = props
  const [currentPoint, setCurrentPoint] = useState(0)
  const [isChecked, setIsChecked] = useState(false)

  const getFooter = () => {
    return (
      <Footer>
        <ActionButtons>
          <Button onClick={handleSubmit}>Save</Button>
        </ActionButtons>
      </Footer>
    )
  }

  useEffect(() => {
    if (goal) {
      setCurrentPoint(goal.percentage || 0)
      setIsChecked(goal.percentage === 100)
    }
  }, [goal])

  const handleSubmit = () => {
    onSubmit(goal, currentPoint)
  }

  const onToggleClick = (checked: boolean) => {
    if (checked) {
      setCurrentPoint(100)
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }

  const onChange = (value: number) => {
    setCurrentPoint(value)
    setIsChecked(value === 100)
  }

  return (
    <CustomModal show={props.show} onClose={props.onClose}>
      <ModalContent
        title="Review Progress"
        subtitle="Aim to review your goal every two weeks, this allows you time to determine if your strategies were effective."
        footer={getFooter()}
        onClose={props.onClose}
      >
        <Container>
          <Icon src="/calendar.png" />
          <Content>
            <Title>Slide the progress slider along if you made progress</Title>
            <Options>
              <SliderContainer>
                <Prefix className="text-gray-400">0%</Prefix>
                <SliderWrapper>
                  <SliderWithTooltip
                    tipFormatter={percentFormatter}
                    railStyle={{
                      backgroundColor: "rgba(51,51,51,0.1)",
                      height: 8,
                    }}
                    trackStyle={{
                      backgroundColor: theme.colors.primary,
                      height: 8,
                      width: 8,
                    }}
                    handleStyle={{
                      border: 0,
                      borderRadius: 0,
                      height: 12,
                      width: 4,
                      marginLeft: 0,
                      marginTop: -2,
                      backgroundColor: theme.colors.primary,
                    }}
                    step={10}
                    defaultValue={currentPoint}
                    value={currentPoint}
                    startPoint={0}
                    // @ts-ignore
                    tipProps={{ overlayClassName: "slider-tip" }}
                    onChange={onChange}
                  />
                </SliderWrapper>
                <Suffix className="text-gray-400">100%</Suffix>
              </SliderContainer>
              <CheckWrapper>
                <Check
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e: any) => {
                    onToggleClick(e.target.checked)
                  }}
                />
                <Text>Set as completed</Text>
              </CheckWrapper>
            </Options>
            {/* <EditWrapper>
              <StyledEditIcon />
              Edit Goal
            </EditWrapper> */}
          </Content>
        </Container>
      </ModalContent>
    </CustomModal>
  )
}

export default GoalReview
