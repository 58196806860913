import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  height: 8px;
  border-radius: 4px;
  background: rgba(51, 51, 51, 0.1);
`
const Bar: any = styled.div`
  border-radius: 4px;
  height: 100%;
  width: ${(props: any) => props.$percent}%;
  background: ${props => props.theme.colors.primary};
`

interface Props {
  percent: number
  className?: string
}

const ProgressBar: React.FC<Props> = props => {
  return (
    <Wrapper className={props.className}>
      <Bar $percent={props.percent} />
    </Wrapper>
  )
}

export default ProgressBar
