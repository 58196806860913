import { Button } from "components/buttons"
import MobileModal from "components/MobileModal"
import { IGoalStep, IGoalStepOption } from "models/GoalStep"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { breakpoints } from "utils"
import Modal from "../../Modal"
import ModalContent from "../../Modal/ModalContent"
import GoalStep from "./GoalStep"
import { UserGoal } from "api/types"

import ErrorText from "components/ErrorText"

const Container = styled.div``
const CustomModal = styled(Modal)`
  @media (min-width: ${breakpoints.desktop}px) {
    max-width: 650px;
  }
`
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
const FooterInfo = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.secondary};
`
const ActionButtons = styled.div`
  & button:nth-child(1) {
    margin-right: 16px;
  }
`
interface FormError {
  text: string | null
}
interface Props {
  steps: IGoalStep[]
  setSteps: Function
  totalSteps: number
  show: boolean
  onSubmit: Function
  onClose: Function
  goal: UserGoal | null
  successful: boolean
}

enum ErrorType {
  goal,
  motivations,
  obstacles,
  strategies,
  executions,
}

const GoalFormModal: React.FC<Props> = props => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [error, setError] = useState<FormError>({
    text: null,
  })
  const title = "Set your Goal"
  const subtitle =
    "The goal you set for yourself should be achievable and your expectations realistic."

  useEffect(() => {
    if (props.successful) {
      setCurrentStepIndex(0)
    }
  }, [props.successful])

  const validate = (index: number) => {
    const hasValue = props.steps[index]?.options?.find(opt => opt.selected)
    if (hasValue) {
      setCurrentStepIndex(index + 1)
    } else {
      setError({
        text: `Please select at one ${props.steps[index].title}`,
      })
      return false
    }
  }
  const onClose = () => {
    props.onClose()
    setError({
      text: "",
    })
  }
  const handleSubmit = () => {
    const hasValue = props.steps[4]?.customText
    if (hasValue) {
      props.onSubmit(props.steps, props.goal)
    } else {
      setError({
        text: "Please type",
      })
    }
  }

  const onTagClick = (stepId: string, option: IGoalStepOption) => {
    console.log("step ID " + stepId + "option " + option)
    const updatedSteps = props.steps.map((step: IGoalStep) => {
      if (step.id === stepId && step.options) {
        step.options = step.options.map(opt => {
          return Object.assign({}, opt, {
            selected:
              opt.id === option.id
                ? !opt.selected
                : step.id === "goal"
                ? false
                : opt.selected,
          })
        })
      }
      return step
    })
    setError({
      text: "",
    })
    props.setSteps(updatedSteps)
  }

  const updateOptions = (stepId: string, options: IGoalStepOption[]) => {
    const updatedSteps = props.steps.map(step => {
      if (stepId === step.id) {
        step.options = options
      }
      return step
    })
    props.setSteps(updatedSteps)
  }

  const updateCustomText = (stepId: string, text: string) => {
    const updatedSteps = props.steps.map(step => {
      if (stepId === step.id) {
        step.customText = text
      }
      return step
    })
    props.setSteps(updatedSteps)
  }

  const goBack = () => {
    setCurrentStepIndex(currentStepIndex - 1)
    setError({
      text: "",
    })
  }

  const getActions = () => {
    return (
      <ActionButtons>
        {currentStepIndex > 0 && (
          <Button outline onClick={goBack}>
            Back
          </Button>
        )}
        {currentStepIndex === props.totalSteps - 1 && (
          <Button onClick={handleSubmit}>Save</Button>
        )}
        {currentStepIndex < props.totalSteps - 1 && (
          <Button onClick={() => validate(currentStepIndex)}>Next</Button>
        )}
      </ActionButtons>
    )
  }
  const getFooter = () => {
    return (
      <Footer>
        <FooterInfo>
          Step {currentStepIndex + 1} of {props.totalSteps}
        </FooterInfo>
        {getActions()}
      </Footer>
    )
  }
  const getContent = (isPhoneView: boolean) => {
    let customOptions: IGoalStepOption[] | string = ""
    const stepsCustomOpts = props.steps[currentStepIndex].options
    if (stepsCustomOpts) {
      customOptions = stepsCustomOpts.filter(step => step.isCustom)
    }

    return (
      <ModalContent
        title={title}
        containerStyle={{
          height: isPhoneView ? "calc(100vh - 100px)" : "auto",
        }}
        subtitle={subtitle}
        footer={getFooter()}
        onClose={onClose}
      >
        <ErrorText text={error.text} />
        {props.steps && props.steps.length > 0 && (
          <GoalStep
            data={props.steps[currentStepIndex]}
            customOptions={customOptions}
            onTagClick={onTagClick}
            updateCustomText={updateCustomText}
            updateOptions={updateOptions}
          />
        )}
      </ModalContent>
    )
  }

  const isPhoneView =
    typeof window !== `undefined` && window.innerWidth <= breakpoints.phone

  if (isPhoneView) {
    return (
      <MobileModal show={props.show}>{getContent(isPhoneView)}</MobileModal>
    )
  }

  return (
    <Container>
      <CustomModal show={props.show} onClose={onClose}>
        {getContent(isPhoneView)}
      </CustomModal>
    </Container>
  )
}

export default GoalFormModal
