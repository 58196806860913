import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import BloodSvg from "images/blood.inline.svg"
import OvulationSvg from "images/ovulation.inline.svg"
import HeartSvg from "images/heart.inline.svg"
import isSameDay from "date-fns/isSameDay"
import { IEvent } from "models/IEvent"
import { CalendarEventType } from "components/types"

const Container: any = styled.div`
  display: flex;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  overflow: visible;
`
const Wrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  margin-top: 5px;
  padding-top: 10px;
  width: 100%;
  & > .date-label {
    color: ${props => props.theme.colors.secondary};
  }
  ${(props: any) => {
    if (props.$selected) {
      return css`
        border: 1px solid ${props.theme.colors.primary} !important;
      `
    }
  }}
  ${(props: any) => {
    let borderColor
    if (props.$isMenstrualDay) {
      borderColor = "#00B7A2"
    } else if (props.$isFertileDay) {
      borderColor = "#00B7A2"
    }
    if (borderColor) {
      return css`
        border-top: 3px solid ${borderColor} !important;
      `
    }
  }}
  ${(props: any) => {
    if (!props.$isOffRange) {
      return css`
        background: rgba(0, 0, 0, 0.03);
        & > .date-label {
          color: #333333;
        }
      `
    }
  }}
  ${(props: any) => {
    if (props.$isCurrentDay) {
      return css`
        & > .date-label {
          color: ${props.theme.colors.primary};
        }
      `
    }
  }}
`
const Label = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.colors.secondary} !important;
`
const Icons = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px 6px;
  margin-top: auto;
`
const commonIconStyles = css`
  ${(props: any) => {
    if (!props.$show) {
      return css`
        display: none;
      `
    }
  }}
`
const BloodIcon = styled(BloodSvg)`
  ${commonIconStyles};
  width: 17px;
  height: 22px;
`
const HeartIcon = styled(HeartSvg)`
  ${commonIconStyles};
  width: 17px;
  height: 22px;
`
const OvulationIcon = styled(OvulationSvg)`
  ${commonIconStyles};
  width: 20px;
  height: 20px;
  margin-right: 2px;
`

interface Props {
  isOffRange: boolean
  date: Date
  event: IEvent
  popupVisible: boolean
  popupLocation: {
    right: number
    top: number
  }
  onClick: Function
}

const MonthDateHeader: React.FC<Props> = props => {
  const isCurrentDay = isSameDay(props.date, new Date())
  const events = _.get(props, "event.events")
  const hasEvent = (eventType: CalendarEventType) =>
    !props.isOffRange && _.includes(events, eventType)

  return (
    <Container className="month-date-header">
      <Wrapper
        onClick={(e: any) => {
          const rect = e.target.getBoundingClientRect()
          props.onClick(props.date, rect, {
            isFertileDay: hasEvent(CalendarEventType.FERTILE),
            isMenstrualDay: hasEvent(CalendarEventType.MENSTRUAL),
          })
        }}
        $selected={props.popupVisible}
        $isOffRange={props.isOffRange}
        $isCurrentDay={isCurrentDay}
        $isMenstrualDay={hasEvent(CalendarEventType.MENSTRUAL)}
        $isFertileDay={hasEvent(CalendarEventType.FERTILE)}
      >
        <Label className="date-label">{props.date.getDate()}</Label>
        <Icons>
          <OvulationIcon $show={hasEvent(CalendarEventType.OVULATION)} />
          <HeartIcon $show={hasEvent(CalendarEventType.INTIMIACY)} />
          <BloodIcon $show={hasEvent(CalendarEventType.MENSURATION)} />
        </Icons>
      </Wrapper>
    </Container>
  )
}

export default MonthDateHeader
