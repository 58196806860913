import React, { MouseEvent } from "react"
import styled, { css } from "styled-components"
import CheckSvg from "images/check.inline.svg"
import { RiDeleteBinLine } from "react-icons/ri"
import theme from "components/layout/theme"

const Container: any = styled.div`
  background: rgba(51, 51, 51, 0.05);
  border-radius: 16px;
  padding: 6px 12px;
  display: flex;
  width: 236px;
  align-items: center;
  margin-right: 17px;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;
  color: ${props => props.theme.colors.primary};
  ${(props: any) => {
    if (props.$active) {
      return css`
        background: ${theme.colors.blue};
        color: white;
      `
    }
  }}
`
const TagIcon = styled.img`
  margin: 0;
  background-color: #f5f5f5;
  padding: 1px;
  margin-right: 10px;
  width: 23px;
  height: 23px;
  /* border: 1px solid white; */
  border-radius: 50%;
`
const CheckIcon = styled(CheckSvg)`
  fill: ${props => props.theme.colors.primary};
  margin-right: 6px;
  height: 10px;
  width: 20px;
  ${(props: any) => {
    if (props.$active) {
      return css`
        fill: white;
      `
    }
  }}
`
const Text = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  overflow-wrap: anywhere;
`
const RemoveIcon = styled(RiDeleteBinLine)`
  margin-left: 6px;
`

interface Props {
  id: string
  text: string
  title: string
  tagIcon: string
  onClick: Function
  onChange?: Function
  onRemove?: Function
  selected?: boolean
  isCustom?: boolean
}

const Tag: React.FC<Props> = props => {
  console.log("prosp", props.title)
  return (
    <Container onClick={props.onClick} $active={props.selected}>
      {props.title === "Pick your goals" ? (
        <TagIcon src={props.tagIcon} />
      ) : (
        <CheckIcon $active={props.selected} />
      )}

      <Text>
        {props.text}
        {props.isCustom && (
          <RemoveIcon
            onClick={(event: MouseEvent) =>
              props?.onRemove && props?.onRemove()
            }
          />
        )}
      </Text>
    </Container>
  )
}

export default Tag
