import { Amplify } from "aws-amplify"
import { AWS_CONFIG } from "../../../../aws-config"
import { Button } from "components/buttons"
import GoalSvg from "images/goal.inline.svg"
import AddSvg from "images/plus-circle.inline.svg"
import { IGoalStep, IGoalStepOption } from "models/GoalStep"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Card from "../Card"
import GoalFormModal from "./GoalFormModal"
import GoalTracker from "./GoalTracker"
import { apiPutGoals } from "api/queries"
import GoalReview from "./GoalFormModal/GoalReview"
import shortid from "shortid"
import GoalCompleted from "./GoalCompleted"
import { GoalSteps } from "../../../config"
import { UserGoal } from "api/types"
import { toast } from "react-toastify"
import toastError from "utils/toastError"
import _ from "lodash"
import { breakpoints } from "utils"
import { UserGoalInput } from "api/graphql/API"

const Container = styled(Card)`
  margin-bottom: 20px;
  border: 1px solid lightgray;
`
const Description = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  @media (max-width: ${breakpoints.phone}px) {
    padding-bottom: 15px;
  }
`
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const AddIcon = styled(AddSvg)`
  cursor: pointer;
  stroke: ${props => props.theme.colors.white};
  height: 26px;
  width: 26px;
  margin-left: 8px;
  margin-right: -8px;
`
const NotFound = styled.div`
  margin-top: 64px;
  margin-bottom: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const GoalIcon = styled(GoalSvg)`
  fill: rgba(0, 0, 0, 0.15);
  height: 59px;
  width: 59px;
`
const InfoText = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${props => props.theme.colors.secondary};
  padding: 0 36px;
`

const ButtonAdd = styled.button`
  background: ${props => props.theme.colors.secondary};
  margin-right: 5px;
`

interface Props {
  goals: UserGoal[] | null
  updateUser: Function
}

const GoalsCard: React.FC<Props> = ({ goals: storageGoals, updateUser }) => {
  const initSteps = _.cloneDeep(GoalSteps).map(step => {
    if (step.options && step.options.length) {
      const options = step.options.map(opt => ({
        ...opt,
        id: shortid.generate(),
        isCustom: false,
        selected: false,
      }))
      return {
        ...step,
        options,
      }
    } else {
      return { ...step }
    }
  }) as IGoalStep[]
  const totalSteps = initSteps.length
  const [steps, setSteps] = useState<IGoalStep[]>(initSteps)
  const [completedGoals, setCompletedGoals] = useState<UserGoal[]>([])
  const [formIsVisible, setFormIsVisible] = useState<boolean>(false)
  const [goalReviewVisible, setGoalReviewVisible] = useState<boolean>(false)
  const [proceedGoals, setProceedGoals] = useState<UserGoal[]>([])
  const [editingGoal, setEditingGoal] = useState<UserGoal | null>(null)
  const [goals, setGoals] = useState<UserGoal[] | null>(null)
  const [successful, setSuccessful] = useState<boolean>(false)

  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
  }, [])
  useEffect(() => {
    setGoals(storageGoals)
  }, [storageGoals])

  useEffect(() => {
    if (goals && goals.length) {
      setCompletedGoals(goals.filter(goal => goal.isCompleted))
      setProceedGoals(goals.filter(goal => !goal.isCompleted))
    }
  }, [goals])

  const onFormClose = () => {
    setSteps(_.cloneDeep(initSteps))
    setEditingGoal(null)
    setFormIsVisible(false)
    setSuccessful(true)
  }
  const onGoalReviewClose = () => {
    setEditingGoal(null)
    setGoalReviewVisible(false)
  }

  const addGoal = () => {
    setSteps(_.cloneDeep(initSteps))
    setFormIsVisible(true)
  }
  const onFormSubmit = async (steps: IGoalStep[], goal: UserGoal | null) => {
    setSuccessful(false)
    const data: any = {
      id: goal ? goal.id : null,
      goal: {},
      motivations: [],
      obstacles: [],
      strategies: [],
      executions: [],
      percentage: 0,
      isCompleted: false,
      createdOn: new Date().toISOString(),
      completedOn: null,
    }
    steps.forEach(step => {
      if (step.id === "executions") {
        data.executions = [
          {
            text: step.customText,
            ref: step.customText,

            isCustom: true,
          },
        ]
      } else if (step.id === "goal") {
        const {
          text,
          ref,
          tagIcon,
          isCustom,
        } = (step.options as IGoalStepOption[]).find(
          opt => opt.selected
        ) as IGoalStepOption
        data[step.id] = { text, ref, isCustom }
      } else {
        data[step.id] = (step.options as IGoalStepOption[])
          .filter(opt => opt.selected)
          .map(({ text, ref, isCustom }) => ({
            text,
            ref,
            isCustom,
          }))
      }
    })
    try {
      await apiPutGoals(data)
      setFormIsVisible(false)
      setEditingGoal(null)
      setSuccessful(true)
      updateUser()
    } catch (e: any) {
      setEditingGoal(null)
      setSuccessful(false)
      console.error(e)
      toastError(toast, e.errors[0].message)
    }
  }

  const updatePercentage = async (goal: UserGoal, percentage: number) => {
    const updated = (goals as UserGoal[]).find(
      item => item.id === goal.id
    ) as UserGoalInput
    updated.percentage = percentage
    updated.isCompleted = percentage === 100
    updated.completedOn = new Date().toISOString()
    try {
      await apiPutGoals(updated)
      setGoalReviewVisible(false)
      setEditingGoal(null)
      updateUser()
    } catch (e: any) {
      setEditingGoal(null)
      console.error(e)
      toastError(toast, e.errors[0].message)
    }
  }

  const getTitle = () => (
    <Header>
      My goals
      {proceedGoals.length ? (
        <ButtonAdd id="add-goal" onClick={() => addGoal()}>
          <AddIcon />
        </ButtonAdd>
      ) : null}
    </Header>
  )
  const regenerateSteps = (goal: UserGoal) => {
    const data = _.pick(goal, [
      "goal",
      "motivations",
      "executions",
      "obstacles",
      "strategies",
    ])
    const stepInitial = _.cloneDeep(initSteps)
    stepInitial.forEach(step => {
      if (step.id === "goal") {
        if (data.goal?.isCustom) {
          ;(step.options as IGoalStepOption[]).push({
            id: shortid.generate(),
            text: data.goal?.text as string,
            ref: data.goal?.ref as string,
            tagIcon: data.goal?.tagIcon as string,
            isCustom: true,
            selected: true,
          })
        } else {
          step.options?.forEach(opt => {
            if (opt.text === data.goal?.text) {
              opt.selected = true
            }
          })
        }
      } else if (step.id === "executions") {
        step.customText = (data?.executions && data?.executions[0]?.text) || ""
      } else {
        type key =
          | "goal"
          | "motivations"
          | "obstacles"
          | "strategies"
          | "executions"
        const eachData: any = data[step.id as key]
        const options: IGoalStepOption[] = step.options as IGoalStepOption[]
        options.forEach(opt => {
          const selected = eachData.find((val: any) => val.text === opt.text)
          opt.selected = !!selected
        })
        let custom = eachData.filter((val: any) => val.isCustom)
        custom = custom.length
          ? custom.map((item: any) => ({
              id: shortid.generate(),
              text: item?.text,
              ref: item?.ref,
              isCustom: true,
              selected: true,
            }))
          : []
        options.push(...custom)
      }
    })
    setSteps(stepInitial)
  }
  const setForm = (goal: UserGoal) => {
    setFormIsVisible(true)
    regenerateSteps(goal)
    setEditingGoal(goal)
  }
  const setGoalReview = (goal: UserGoal) => {
    setGoalReviewVisible(true)
    setEditingGoal(goal)
  }

  return (
    <>
      <Container title={getTitle()}>
        <Description>
          Changing health habits is an ongoing process and needs constant
          attention. We encourage you to take small steps that are achievable.
          In this section, you can create goals and measure your progress.
          <br />
          <br />
          Click on the plus icon to add a goal that is important for you.
        </Description>
        {!proceedGoals.length ? (
          <NotFound>
            <GoalIcon />
            <InfoText>
              Set goals which you want to track and improve everyday
            </InfoText>
            <Button onClick={() => setFormIsVisible(true)} id="add-goal-btn">
              Add Goals
            </Button>
          </NotFound>
        ) : (
          <GoalTracker
            setForm={setForm}
            setGoalReview={setGoalReview}
            goals={proceedGoals}
          />
        )}
        <GoalFormModal
          steps={steps}
          goal={editingGoal}
          setSteps={setSteps}
          totalSteps={totalSteps}
          successful={successful}
          show={formIsVisible}
          onClose={onFormClose}
          onSubmit={onFormSubmit}
        />
        <GoalReview
          show={goalReviewVisible}
          onClose={onGoalReviewClose}
          onSubmit={updatePercentage}
          goal={editingGoal}
        />
      </Container>
      {completedGoals.length ? (
        <Container title="My completed goals">
          <Description>
            In this section, you see the goals that you have already achieved.
            Amazing job!
          </Description>
          <GoalCompleted goals={completedGoals} setGoalReview={setGoalReview} />
        </Container>
      ) : null}
    </>
  )
}

export default GoalsCard
