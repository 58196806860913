import MobileModal from "components/MobileModal"
import Portal from "components/Portal"
import format from "date-fns/format"
import _ from "lodash"
import { ISymptomAnswer } from "models/ISymptomAnswer"
import { ISymptomOption } from "models/ISymptomOption"
import { ISymptomQuestion } from "models/ISymptomQuestion"
import React, { useEffect, useState } from "react"
import { Calendar, ToolbarProps } from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"
import shortid from "shortid"
import styled from "styled-components"
import { breakpoints } from "utils"
import "./calendar.scss"
import CalendarToolbar from "./CalendarToolbar"
import Card from "../Card"
import DateCellWrapper from "./DateCellWrapper"
import MonthDateHeader from "./MonthDateHeader"
import MonthHeader from "./MonthHeader"
import SymptomsModal from "../SymptomsModal"
import SymptomsPopup, { SymptomPopupProps } from "./SymptomsPopup"
import { getCalendarLocalizer } from "../utils"
import { apiFetchUserCalendar } from "api/queries"
import {
  UserCalendarEvent,
  CalendarSymptom,
  CalendarEventType,
} from "components/types"
import { SYMPTOM_QUESTIONS } from "./constants"
import { apiPutSymptoms } from "api/queries"
import { PutSymptomsInput } from "api/graphql/API"
import { toast } from "react-toastify"
import toastError from "utils/toastError"
import { apiRemoveSymptom } from "api/queries"

const Container = styled(Card)`
  position: relative;
  overflow: visible;
  border: 1px solid lightgray;
`
const Description = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  @media (max-width: ${breakpoints.phone}px) {
    padding-bottom: 15px;
  }
`

interface Props {
  userId?: string
}
interface DayType {
  isFertileDay: boolean
  isMenstrualDay: boolean
}

const parseAnswers = (questions: ISymptomQuestion[]): ISymptomAnswer[] => {
  return questions.map(q => {
    const selectedOption = q.options.find(opt => opt.selected)
    return {
      id: q.id,
      name: q.name,
      selectedOption,
      iconSrc: q.grayIconSrc,
    }
  })
}

const parseSubmitAnswers = (
  questions: ISymptomQuestion[],
  popupDate: Date
): PutSymptomsInput[] | null => {
  const symptomDate = format(popupDate, "yyyy-MM-dd")
  const selectedQuestions = questions.filter(q => {
    return q.options.find(opt => opt.selected)
  })
  if (!selectedQuestions.length) return null
  return selectedQuestions.map(q => {
    const getOpts = (opt: ISymptomOption) => ({
      name: opt.name,
      id: opt.value,
      icon: opt.icon,
      color: opt.color,
    })
    const symptomType = {
      id: q.id,
      name: q.name,
      symptomQuestion: q.description,
      choices: q.options.map(getOpts),
    }
    const selectedOption = q.options.find(opt => opt.selected)
    const value = getOpts(selectedOption as ISymptomOption)
    return q.symptomId
      ? {
          symptomDate,
          symptomType,
          value,
          id: q.symptomId,
        }
      : {
          symptomDate,
          symptomType,
          value,
        }
  })
}

const PatientCalendar: React.FC<Props> = ({ userId }) => {
  const [events, setEvents] = useState<UserCalendarEvent[]>()
  const [popupDate, setPopupDate] = useState<Date | null>(null)
  const [popupLocation, setPopupLocation] = useState({})
  const [selectedDayType, setSelectedDayType] = useState<DayType>()
  const [formIsVisible, setFormIsVisible] = useState<boolean>(false)
  const [popupIsVisible, setPopupIsVisible] = useState<boolean>(false)

  const [questions, setQuestions] = useState<ISymptomQuestion[]>([])
  const [answers, setAnswers] = useState<ISymptomAnswer[]>()
  const [symptoms, setSymptoms] = useState<CalendarSymptom[]>()
  const [hasNoContent, setHasNoContent] = useState<boolean>(false)

  const loadEventsSymptoms = async (
    userId: string,
    month: number,
    year: number
  ) => {
    const userCalendar = await apiFetchUserCalendar({ userId, month, year })
    const _symptoms = userCalendar?.symptoms as CalendarSymptom[]
    if (!_symptoms || !_symptoms.length) return
    // check if bleeding is severe
    const bleedingAnswer = _.filter(
      _symptoms,
      sym =>
        sym?.symptomType?.name === "Vaginal Bleeding" &&
        sym?.value?.id?.toLocaleLowerCase().includes("severe")
    )
    const _events = _.uniqBy(_symptoms, "symptomDate").map(item => {
      const isBleeding = bleedingAnswer.find(
        ans => (ans as CalendarSymptom).symptomDate === item.symptomDate
      )
      if (isBleeding) {
        return {
          id: shortid.generate(),
          date: item.symptomDate,
          events: [CalendarEventType.MENSTRUAL],
        }
      }
      return {
        id: shortid.generate(),
        date: item.symptomDate,
        events: [CalendarEventType.FERTILE],
      }
    })
    setEvents(_events)
    setSymptoms(_symptoms)
  }

  const onDateClick = (d: Date, rect: any, dayType: DayType) => {
    setSelectedDayType(dayType)
    if (popupDate && isSameDate(d, popupDate)) {
      setPopupIsVisible(false)
      setPopupDate(null)
    } else {
      setPopupIsVisible(true)
      setPopupDate(d)
      let top = rect.y + document.documentElement.scrollTop - 200
      setPopupLocation({
        top,
        left: rect.x + rect.width + 20,
      })
    }
  }
  const isSameDate = (d1: Date, d2: Date) =>
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()

  useEffect(() => {
    if (userId) {
      const currentMonth = new Date().getMonth() + 1
      const year = new Date().getFullYear()
      loadEventsSymptoms(userId as string, currentMonth, year)
    }
    document.addEventListener("click", e => {
      // @ts-ignore
      const symptomsPopup = e.target.closest(".symptoms-popup") // @ts-ignore
      const dateHeaderCell = e.target.closest(".month-date-header") // @ts-ignore
      const symptomsModal = e.target.closest(".symptoms-modal")
      if (!symptomsPopup && !dateHeaderCell && !symptomsModal) {
        // setPopupDate(null)
        setPopupIsVisible(false)
      }
    })
  }, [userId])

  const getSymptomAnswers = (date: Date) => {
    const pickAnswerByDate = _.filter(symptoms, o =>
      isSameDate(new Date(o.symptomDate as string), date)
    )
    return pickAnswerByDate
  }

  const loadQuestions = async () => {
    if (!popupDate) return
    let answerData = getSymptomAnswers(popupDate)
    const allQuestions: ISymptomQuestion[] = []
    const _answers: ISymptomAnswer[] = []
    const cloneQuestions = _.cloneDeep(SYMPTOM_QUESTIONS)
    if (answerData.length) {
      _.forEach(cloneQuestions, (q: ISymptomQuestion) => {
        let selectedOption: ISymptomOption | undefined = undefined
        const foundAnswer = _.find(
          answerData,
          ans => ans.symptomType?.id === q.id
        )
        q.options = _.map(q.options, opt => {
          const selected = _.isEqual(_.get(foundAnswer, "value.id"), opt.value)
          if (selected) {
            selectedOption = opt
            q.symptomId = foundAnswer?.id
          }
          opt.selected = selected
          return opt
        })
        _answers.push({
          id: q.id,
          iconSrc: q.iconSrc,
          name: q.name,
          selectedOption,
        })
        allQuestions.push({ ...q })
      })
      setAnswers(_answers)
      setQuestions(allQuestions)
    } else {
      setQuestions([...cloneQuestions])
      setAnswers([])
    }
    setHasNoContent(!answerData.length)
  }
  useEffect(() => {
    loadQuestions()
  }, [popupDate])

  const components: any = {
    toolbar: (toolbarProps: ToolbarProps) => (
      <CalendarToolbar
        userId={userId}
        onMonthChange={loadEventsSymptoms}
        {...toolbarProps}
      />
    ),
    dateCellWrapper: DateCellWrapper,
    month: {
      header: MonthHeader,
      dateHeader: (dateHeaderProps: any) => (
        <MonthDateHeader
          onClick={onDateClick}
          popupVisible={
            popupDate && isSameDate(dateHeaderProps.date, popupDate)
          }
          event={_.find(events, evt =>
            isSameDate(new Date(evt.date as string), dateHeaderProps.date)
          )}
          {...dateHeaderProps}
        />
      ),
    },
  }

  const onSymptomsFormSubmit = (eventGroup?: any) => {
    if (!eventGroup) return
    let updatedEvts = _.cloneDeep(events) || []
    const foundEvt = _.find(events, { id: _.get(eventGroup, "id") })
    if (foundEvt) {
      updatedEvts = _.map(events, evt => {
        if (eventGroup && evt.id === eventGroup.id) {
          return _.assign({}, evt, eventGroup)
        }
        return evt
      })
    } else {
      updatedEvts.push(eventGroup)
    }
    setEvents(updatedEvts)
  }

  const onDeleteBtnClick = async (date: Date) => {
    const symptomsByDate = _.groupBy(symptoms, "symptomDate")
    const dateString = format(date, "yyyy-MM-dd")
    const ids = symptomsByDate
      ? (_.map(symptomsByDate[dateString], "id") as string[])
      : []
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    try {
      await apiRemoveSymptom(ids)
      setPopupIsVisible(false)
      setPopupDate(null)
      setEvents([])
      setSymptoms([])
    } catch (e: any) {
      toastError(toast, e.message)
    }
  }

  const title =
    typeof window !== `undefined` && window.innerWidth <= breakpoints.phone
      ? "Track my symptoms"
      : "Track my symptoms"

  const isPhoneView =
    typeof window !== `undefined` && window.innerWidth <= breakpoints.phone
  const symptomPopupProps: SymptomPopupProps = {
    dayType: selectedDayType,
    date: popupDate,
    popupLocation: popupLocation,
    showFormModal: () => {
      setPopupIsVisible(false)
      setFormIsVisible(true)
    },
    hideFormModal: () => {
      setPopupIsVisible(false)
      setFormIsVisible(false)
    },
    formModalIsVisible: formIsVisible,
    hasNoContent,
    answers,
    onDeleteBtnClick,
  }
  if (!isPhoneView) {
    symptomPopupProps.style = {
      ..._.pick(popupLocation, ["left", "top"]),
      position: "absolute",
      width: "375px",
      minHeight: "440px",
    }
  }

  const onOptionSelect = (questionId: string, option: ISymptomOption) => {
    const questionIndex = _.findIndex(questions, { id: questionId })
    if (questionIndex === -1) {
      return
    }
    const question = _.cloneDeep(questions[questionIndex])
    question.options = _.map(question.options, qOption => {
      qOption.selected = qOption.value === option.value
      return qOption
    })

    setQuestions(
      _.map(questions, (q, i) => {
        if (questionIndex === i) {
          return question
        }
        return q
      })
    )
  }

  const onSubmit = async () => {
    if (userId) {
      const inputAnswers = parseAnswers(questions)
      const submitAnswers = parseSubmitAnswers(questions, popupDate as Date)
      if (!submitAnswers) {
        toastError(toast, "Please select.")
        return
      }
      // check if bleeding is severe
      const bleedingAnswer = _.find(
        inputAnswers,
        ans => ans.name === "Vaginal Bleeding"
      )
      const hasSevereBleeding = _.includes(
        _.get(bleedingAnswer, "selectedOption.value", "").toLowerCase(),
        "severe"
      )

      try {
        await apiPutSymptoms(submitAnswers as PutSymptomsInput[])
        const month = (popupDate as Date).getMonth() + 1
        const year = (popupDate as Date).getFullYear()
        loadEventsSymptoms(userId as string, month, year)
      } catch (e) {
        console.error(e)
      }

      let evt
      if (hasNoContent && popupDate) {
        evt = {
          id: shortid(),
          date: popupDate,
          events: [CalendarEventType.MENSTRUAL],
        }
        if (!hasSevereBleeding) {
          evt.events = [CalendarEventType.FERTILE]
        }
      } else {
        const eventGroup =
          popupDate &&
          _.find(events, item =>
            isSameDate(new Date(item.date as string), popupDate)
          )
        if (eventGroup) {
          evt = {
            id: eventGroup.id,
            events: [CalendarEventType.MENSTRUAL],
          }
          if (!hasSevereBleeding) {
            evt.events = [CalendarEventType.FERTILE]
          }
        }
      }
      setAnswers(_.assign({}, answers, { answers: inputAnswers }))
      setHasNoContent(false)
      setFormIsVisible(false)
      onSymptomsFormSubmit(evt)
    }
  }

  return (
    <Container title={title}>
      <Description>
        Track your symptoms by logging them every day or at least once a week.
        This will help you track the different effects early menopause has on
        you and will help you to discuss them with your health professional.
        <br />
        <br />
        Click on a day in the calendar and add your symptoms.
      </Description>
      <Calendar
        defaultView="month"
        localizer={getCalendarLocalizer()}
        startAccessor="start"
        endAccessor="end"
        events={[]}
        style={{ height: 500 }}
        components={components}
      />
      <MobileModal show={popupIsVisible && isPhoneView}>
        <SymptomsPopup {...symptomPopupProps} />
      </MobileModal>
      {popupIsVisible && !isPhoneView && (
        <Portal>
          <SymptomsPopup {...symptomPopupProps} />
        </Portal>
      )}
      <SymptomsModal
        date={popupDate}
        show={formIsVisible}
        onClose={() => setFormIsVisible(false)}
        questions={questions}
        onOptionSelect={onOptionSelect}
        onSubmit={onSubmit}
      />
    </Container>
  )
}

export default PatientCalendar
