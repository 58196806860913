import ResourceLink from "components/ResourceLink"
import React from "react"
import styled from "styled-components"
import Card from "./Card"
import { queryAllYaml } from "utils/staticQuery"

const Container = styled(Card)`
  border: 1px solid lightgray;
`
const Wrapper = styled.div`
  margin-top: 18px;
`

interface Props {}

const LinksCard: React.FC<Props> = props => {
  const { usefulLinks } = queryAllYaml()
  return (
    <Container title="Useful links">
      <Wrapper>
        {usefulLinks.edges.map((item: any, i) => (
          <ResourceLink
            key={i.toString()}
            link={item.node.link}
            text={item.node.title}
            textStyles={{ fontSize: "13px" }}
          />
        ))}
      </Wrapper>
    </Container>
  )
}

export default LinksCard
