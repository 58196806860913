import { H7 } from "components/typography"
import ChatSvg from "images/chat-alt.inline.svg"
import MailSvg from "images/mail.inline.svg"
import PhoneSvg from "images/phone.inline.svg"
import InfoSvg from "images/info.inline.svg"
import { IADoctor } from "models/IDoctor"
import React from "react"
import styled from "styled-components"
import Avatar from "react-avatar"
import theme from "components/layout/theme"
import { HiPencil } from "react-icons/hi"
import { RiDeleteBinLine } from "react-icons/ri"
import ClockLoader from "react-spinners/ClockLoader"

const Container = styled.div`
  height: auto;
  padding-bottom: 20px;
`
const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0 16px;
`
const ProfileInfo = styled.div``
const Name = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.colors.secondary};
  display: flex;
  align-items: center;
`
const Specialization = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.theme.colors.primary};
`
const ProfilePhoto = styled(Avatar)`
  border-radius: 50%;
  height: 40px;
  // border: 2px solid ${props => props.theme.colors.primary};
  // margin-bottom: 0;
`
const ContactSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`
const ContactIconWrapper = styled.a`
  padding: 6px;
  background: rgba(51, 51, 51, 0.07);
  border-radius: 50%;
  margin-right: 24px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & svg {
    stroke: #ec4d7b;
    height: 18px;
    width: 18px;
    fill: none;
  }
`
const ContactIcon: React.FC<{ icon: any; href: string }> = ({
  icon: Icon,
  href,
}) => (
  <ContactIconWrapper href={href}>
    <Icon />
  </ContactIconWrapper>
)
const AppointmentDetails = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: ${props => props.theme.colors.secondary};
  margin: 4px 0 16px;
`
const PrescriptionRow = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  margin-top: 10px;
`
const PresCount = styled.div`
  background: #00b7a2;
  border-radius: 3px;
  padding: 1px 5px;
  color: #ffffff;
  margin-right: 8px;
`
const InfoIcon = styled(InfoSvg)`
  margin-left: auto;
  stroke: rgba(51, 51, 51, 0.35);
  height: 14px;
`
const EditIcon = styled(HiPencil)`
  width: 18px;
  height: 18px;
  color: ${theme.colors.primary};
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
`
const RemoveIcon = styled(RiDeleteBinLine)`
  color: ${theme.colors.primary};
  cursor: pointer;
`

interface Props {
  data: IADoctor
  updateDoctor: Function
  removeDoctor: Function
  deleting: boolean
}

const DoctorDetails: React.FC<Props> = props => {
  const {
    fullName,
    specialization,
    emailAddress,
    appointments,
    // profileUrl,
    phoneNumber,
    prescriptions,
  } = props.data
  let newPrescriptions: any[] = []
  if (prescriptions && prescriptions.length) {
    newPrescriptions = prescriptions.filter(item => item?.dailyFrequency)
  }

  return (
    <Container>
      <ProfileSection>
        <ProfileInfo>
          <Name>
            {fullName}
            <EditIcon onClick={() => props.updateDoctor(props.data)} />
            {props.deleting ? (
              <ClockLoader
                size={16}
                color={theme.colors.primary}
                loading={true}
              />
            ) : (
              <RemoveIcon onClick={() => props.removeDoctor(props.data)} />
            )}
          </Name>
          <Specialization>{specialization}</Specialization>
        </ProfileInfo>
        <ProfilePhoto
          name={fullName || " "}
          size="40"
          color={theme.colors.primary}
        />
      </ProfileSection>
      <ContactSection>
        <ContactIcon icon={PhoneSvg} href={`tel:${phoneNumber}`} />
        <ContactIcon icon={MailSvg} href={`mailto:${emailAddress}`} />
        <ContactIcon icon={ChatSvg} href={`sms:${phoneNumber}`} />
      </ContactSection>
      {/* {appointments && (
        <div>
          <H7>APPOINTMENT DETAILS</H7>
          <AppointmentDetails>
            {appointmentDetails.startTime} - {appointmentDetails.endTime},{" "}
            {appointmentDetails.date}
          </AppointmentDetails>
        </div>
      )} */}
      {newPrescriptions && newPrescriptions.length ? (
        <div>
          <H7>PRESCRIPTION</H7>
          {newPrescriptions.map((pres, i) => (
            <PrescriptionRow key={i.toString()}>
              <PresCount>{pres?.dailyFrequency}/Day</PresCount>
              {pres?.medicineName}
              <InfoIcon />
            </PrescriptionRow>
          ))}
        </div>
      ) : null}
    </Container>
  )
}

export default DoctorDetails
