import React from "react"
import styled, { css } from "styled-components"
import ChevLeftSvg from "images/chevron-left.inline.svg"
import ChevRightSvg from "images/chevron-right-small.inline.svg"
import format from "date-fns/format"
import { ToolbarProps } from "react-big-calendar"
import { breakpoints } from "utils"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 24px;
  user-select: none;
`
const Label = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: ${props => props.theme.colors.primary};
  @media (max-width: ${breakpoints.phone}px) {
    font-size: 24px;
  }
`
const Nav = styled.div`
  display: flex;
  align-items: center;
`
const iconStyles = css`
  height: 11px;
  margin: 0;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  @media (max-width: ${breakpoints.phone}px) {
    stroke-width: 2;
    margin-top: -60px;
  }
`
const ChevRightIcon = styled(ChevRightSvg)`
  ${iconStyles};
  height: 19px;
  @media (max-width: ${breakpoints.phone}px) {
    height: 27px;
  }
`
const ChevLeftIcon = styled(ChevLeftSvg)`
  ${iconStyles};
  @media (max-width: ${breakpoints.phone}px) {
    height: 15px;
    margin-right: 8px;
  }
`
const MonthYear = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: ${props => props.theme.colors.secondary};
  margin-right: 6px;
  @media (max-width: ${breakpoints.phone}px) {
    display: none;
  }
`

interface Props {
  onMonthChange: (userId: string, monthIndex: number, yearIndex: number) => void
  userId?: string
}

const CalendarToolbar: React.FC<ToolbarProps & Props> = props => {
  return (
    <Container>
      <Label>{format(props.date, "EEEE, MMM dd, yyyy")}</Label>
      <Nav>
        <MonthYear>{format(props.date, "MMMM, yyyy")}</MonthYear>
        <ChevLeftIcon
          onClick={() => {
            props.onMonthChange(
              props.userId as string,
              props.date.getMonth(),
              props.date.getFullYear()
            )
            props.onNavigate("PREV")
          }}
        />
        <ChevRightIcon
          onClick={() => {
            let nextMonthIndex = props.date.getMonth() + 2
            console.log(props.date, nextMonthIndex, "props.date")
            let yearIndex = props.date.getFullYear()
            if (nextMonthIndex === 12) {
              nextMonthIndex = 0
              yearIndex += 1
            }
            props.onMonthChange(
              props.userId as string,
              nextMonthIndex,
              yearIndex
            )
            props.onNavigate("NEXT")
          }}
        />
      </Nav>
    </Container>
  )
}

export const getCalendarToolbar = (props: any) => <CalendarToolbar {...props} />
export default CalendarToolbar
