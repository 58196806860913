import React from "react"
import { FaEye, FaEyeSlash, FaPrint } from "react-icons/fa"

interface iFooter {
  showMoreButton: boolean
  showLessButton: boolean
  onClickShowMore: () => void
  onClickShowLess: () => void
  onClickPrint: () => void
}

const FooterButton: React.FC<{
  title: string
  Icon: any
  onClick: () => void
}> = ({ title, onClick, Icon }) => (
  <button
    onClick={onClick}
    className="text-gray-600 bg-gray-200 rounded-full py-1 px-4 text-sm flex items-center hover:bg-gray-300 transition duration-100"
    title={title}
  >
    <span className="mr-2">{Icon}</span>
    <span>{title}</span>
  </button>
)

const Footer: React.FC<iFooter> = ({
  showMoreButton,
  showLessButton,
  onClickShowLess,
  onClickShowMore,
  onClickPrint,
}) => {
  return (
    <div className="flex items-center justify-center px-2 lg:px-0">
      <div>
        <div className="flex items-center justify-center">
          {showMoreButton && (
            <FooterButton
              title={"See more"}
              Icon={<FaEye />}
              onClick={onClickShowMore}
            />
          )}
          {showLessButton && (
            <FooterButton
              title={"See less"}
              Icon={<FaEyeSlash />}
              onClick={onClickShowLess}
            />
          )}
        </div>
        {(showLessButton || showMoreButton) && <div className="py-2" />}
      </div>
    </div>
  )
}

export default Footer
