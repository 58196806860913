import Layout from "components/layout"
import SEO from "components/seo"
import React from "react"
import DashboardPage from "components/dashboardPage"

const Dashboard = () => {
  return (
    <Layout containerStyles={{ background: "#F3F3F3" }}>
      <SEO title="Dashboard" />
      {/* @ts-ignore */}
      <DashboardPage />
    </Layout>
  )
}

export default Dashboard
