import { Button } from "components/buttons"
import React from "react"
import styled from "styled-components"
import Card from "./Card"
import { navigate } from "gatsby"
import { graphql, StaticQuery } from "gatsby"
import Head from "./QPL/WithQPLView/Head"
import { breakpoints } from "../../utils"

const Container = styled(Card)``
const Wrapper = styled.div`
  @media (max-width: ${breakpoints.phone}px) {
    border-radius: 12px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

interface Props {}
const NoArticles = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 14px;
`
const query = graphql`
  query {
    quizImage: file(relativePath: { eq: "quiz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const QuizCard: React.FC<Props> = props => {
  return (
    <StaticQuery query={query}>
      {data => (
        <Wrapper>
          <Head
            image={data.quizImage.childImageSharp.fluid}
            title="Self-assessment quiz"
            description="You will be prompted to answer a few simple questions. Based on your answers we will provide some guidance on the next steps to take."
            buttonText="Start now"
            linkTo="self-assessment"
          />

          <NoArticles></NoArticles>
        </Wrapper>
      )}
    </StaticQuery>
  )
}

export default QuizCard
