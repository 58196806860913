import { graphql, navigate, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { breakpoints } from "utils"
import styled from "styled-components"
import { FaEdit } from "react-icons/fa"
import { QuestionPromptList_InternalLink } from "utils/urls"

const Container = styled.div`
  @media (max-width: ${breakpoints.phone}px) {
    padding: 16px 18px;
  }
`

const image_query = graphql`
  query {
    qplImage: file(relativePath: { eq: "qplQuiz.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const NoQPLView = () => {
  return (
    <StaticQuery query={image_query}>
      {data => (
        <Container title="">
          <div className="w-full site-border-radius relative mb-3 ">
            <Img
              fluid={data.qplImage.childImageSharp.fluid}
              style={{ height: "256px" }}
              className="site-border-radius block md:hidden"
            />
            <Img
              fluid={data.qplImage.childImageSharp.fluid}
              style={{ height: "256px" }}
              className="site-border-radius hidden md:block lg:hidden"
            />
            <Img
              fluid={data.qplImage.childImageSharp.fluid}
              style={{ height: "300px" }}
              className="site-border-radius hidden lg:block"
            />
            <div
              style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
              className={`absolute text-center text-white top-0
                          left-0 w-full h-full site-border-radius
                            flex items-center justify-center py-4 px-4 md:px-8 lg:px-4`}
            >
              <div>
                <h1 className="text-white text-lg mb-2">
                  Questions you can ask your doctor
                </h1>
                {/* <p className="text-xs mb-3">
                  The questions list is for women with PCOS of all ages, weights
                  and backgrounds.
                </p> */}
                <div className="flex items-center justify-center">
                  <button
                    className={`text-white button-bg-site-blue bg-site-blue px-3 text-sm
                    leading-none py-2 rounded-full flex items-center`}
                    onClick={() => navigate(QuestionPromptList_InternalLink)}
                  >
                    <span className="mr-2">
                      <FaEdit />
                    </span>
                    <span>Start now</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </StaticQuery>
  )
}

export default NoQPLView
