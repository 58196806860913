import { H2 } from "components/typography"
import RefreshSvg from "images/refresh.inline.svg"
import { UserType, UserGoal } from "api/types"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { breakpoints } from "utils"
import ArticlesCard from "./ArticlesCard"
import BmiCard from "./BmiCard/BmiCard"
import CalendarCard from "./UserCalendar/CalendarCard"
import ConnectCard from "./ConnectCard"
import DoctorsCard from "./DoctorsCard"
import GoalsCard from "./Goals/GoalsCard"
import QuizCard from "./QuizCard"
import LinksCard from "./UsefulLinksCard"
import {
  calculateBMICategory,
  calculateBMI,
  getGreeting,
  getTimestamp,
} from "./utils"
import differenceInYears from "date-fns/differenceInYears"
import QPL from "./QPL"

const Container = styled.div``
const Header = styled.div`
  margin-top: 36px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakpoints.phone}px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled(H2)`
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 10px;
  & > span {
    font-weight: normal;
  }
`
const ProfileName = styled.span`
  width: 200px;
  display: inline-block;
  white-space: pre;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
`
const RefreshIcon = styled(RefreshSvg)`
  margin-right: 6px;
`
const Description = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  margin: 0px;
  @media (max-width: ${breakpoints.phone}px) {
    padding-bottom: 15px;
  }
`
const LastUpdatedAt = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  padding-right: 15px;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.colors.secondary};
`
const Content = styled(Row)`
  padding-right: 15px;
`

interface Props {
  loggedInUser?: UserType
  updateUser: Function
}

const DashboardPage: React.FC<Props> = ({ updateUser, loggedInUser }) => {
  const greeting = getGreeting()
  const [userData, setUserData] = useState<UserType | null>()
  const [name] = userData?.fullName?.split(" ") || [""]
  const [lastUpdated, setLastUpdated] = useState("")
  const [doctors, setDoctors] = useState([])
  const [badges, setBadges] = useState([])
  const [bookmarks, setBookmarks] = useState([])
  const [goals, setGoals] = useState<UserGoal[] | null>(null)

  useEffect(() => {
    setGoals(loggedInUser?.goals as UserGoal[])
    setUserData(loggedInUser)
    fetchCalendar()
  }, [loggedInUser])

  const age = userData?.dateOfBirth
    ? differenceInYears(Date.now(), new Date(userData?.dateOfBirth)).toString()
    : ""
  const fetchCalendar = async () => {
    if (loggedInUser) {
      const { last_updated_at } = loggedInUser
      if (last_updated_at) {
        setLastUpdated(getTimestamp(new Date(last_updated_at)))
      }
      const { _doctors, _badges, _goals, _bookmarks } = makeUserData(
        loggedInUser
      )
      setDoctors(_doctors)
      setBadges(_badges)
      setGoals(_goals)
      setBookmarks(_bookmarks)
    }
  }

  const makeUserData = (user: any) => {
    const _doctors = user.doctors
      ? user.doctors.filter((_item: any) => Boolean(_item))
      : []
    const _badges = user.badges
      ? user.badges.filter((_item: any) => Boolean(_item))
      : []
    const _bookmarks = user?.bookmarkedArticles || []

    return {
      _doctors,
      _badges,
      _bookmarks,
      _goals: user.goals,
    }
  }

  const header = (
    <Header>
      <TitleContainer>
        <Title>
          <span>{greeting}, </span>
          <ProfileName>{name}</ProfileName>
        </Title>
        <Description>
          A healthy dashboard is the key to your healthier lifestyle
        </Description>
      </TitleContainer>
      <LastUpdatedAt>
        <RefreshIcon /> Last Updated, {lastUpdated}
      </LastUpdatedAt>
    </Header>
  )
  let bmiValue = calculateBMI(userData?.weight!, userData?.height!)
  const bmi = bmiValue ? calculateBMICategory(bmiValue) : null
  const bmiCard = (
    <BmiCard
      value={bmiValue}
      // @ts-ignore */}
      bmi={bmi}
      catIndicator={">"}
      catPercent={"25"}
      age={age}
      update={setUserData}
      dateOfBirth={userData?.dateOfBirth || ""}
      weight={userData?.weight!}
      height={userData?.height!}
    />
  )

  const isPhoneView =
    typeof window !== `undefined` &&
    window.innerWidth <= breakpoints.tabletLandscape

  console.log(isPhoneView)

  if (isPhoneView) {
    return (
      <Container>
        {header}

        <CalendarCard userId={loggedInUser?.id} />
        <GoalsCard goals={goals} updateUser={updateUser} />
        {bmiCard}
        <QPL show={true} userProfile={userData || null} />
        <ArticlesCard bookmarks={bookmarks} />
        <DoctorsCard doctors={doctors} userProfile={userData || null} />
        <QuizCard />
        <LinksCard />
        {/* <ConnectCard /> */}
        {/* <AskCard /> */}
      </Container>
    )
  }

  return (
    <Container>
      {header}
      <Content>
        <Col sm="7">
          <CalendarCard userId={loggedInUser?.id} />
          <GoalsCard goals={goals} updateUser={updateUser} />
          {bmiCard}
          {/* <AskCard /> */}
        </Col>
        <Col sm="5">
          <QPL show={true} userProfile={userData || null} />
          <ArticlesCard bookmarks={bookmarks} />
          <DoctorsCard doctors={doctors} userProfile={userData || null} />
          <QuizCard />
          <LinksCard />
          {/* <ConnectCard /> */}
        </Col>
      </Content>
    </Container>
  )
}

export default DashboardPage
