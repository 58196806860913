import React from "react"
import styled from "styled-components"
import ProgressBar from "components/ProgressBar"
import { HiPencil } from "react-icons/hi"
import { GiBackwardTime } from "react-icons/gi"
import theme from "components/layout/theme"
import { UserGoal } from "api/types"
import { IGoalStep, IGoalStepOption } from "../../../models/GoalStep"

const Container = styled.div`
  margin-top: 24px;
`
const Metric = styled.div`
  margin-bottom: 18px;
`
const MetricWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`
const MetricTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colors.secondary};
  flex: 1;
  && span {
    font-size: 12px;
    font-weight: 400;
  }
`
const Info = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${props => props.theme.colors.secondary};
  margin-top: 56px;
  margin-bottom: 16px;
`
const StyledTimeIcon = styled(GiBackwardTime)`
  width: 26px;
  height: 26px;
  color: ${theme.colors.primary};
  cursor: pointer;
  margin-right: 8px;
`
const StyledEditIcon = styled(HiPencil)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  color: ${theme.colors.primary};
`

interface Props {
  goals: UserGoal[] | null
  setForm: Function
  setGoalReview: Function
}

const GoalTracker: React.FC<Props> = ({ goals, setForm, setGoalReview }) => {
  if (!goals) {
    return null
  }
  return (
    <Container>
      {goals && goals.length
        ? goals.map((goal, index) => (
            <Metric key={index}>
              <MetricWrapper>
                <MetricTitle>
                  {goal.goal?.text}{" "}
                  {goal.goal?.ref && <span>/ {goal.goal?.ref}</span>}
                </MetricTitle>
                <div>
                  <StyledTimeIcon onClick={() => setGoalReview(goal)} />
                  <StyledEditIcon onClick={() => setForm(goal)} />
                </div>
              </MetricWrapper>
              <ProgressBar percent={goal.percentage || 0} />
            </Metric>
          ))
        : null}
    </Container>
  )
}

export default GoalTracker
