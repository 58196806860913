import _ from "lodash"
import React, { createRef, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { FileDrop } from "react-file-drop"
import DragSvg from "images/drag.inline.svg"
import { FormControl } from "react-bootstrap"
import { breakpoints } from "utils"
import TrashSvg from "images/trash.inline.svg"

const Container = styled.div`
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
`
const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`
const Content = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
`
const Text: any = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: ${props => props.theme.colors.secondary};
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    ${(props: any) => {
      if (props.$desktopOnly) {
        return css`
          display: none;
        `
      }
    }}
  }
`
const Link = styled.span`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
  cursor: pointer;
`
const FileInput = styled(FormControl)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: none;
`
const DragIcon = styled(DragSvg)`
  fill: #979797;
  margin-right: 8px;
`
export const TrashIcon = styled(TrashSvg)`
  stroke: #b0b0b0;
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-left: 8px;
  &:hover {
    stroke: #777;
  }
`

interface Props {
  onSubmit: Function
  report?: any
}

const DndFileUpload: React.FC<Props> = props => {
  const [uploadedFile, setUploadedFile] = useState<any | null>(null)
  const fileInputRef = createRef()

  const onFileInputClick = () => {
    if (fileInputRef.current) {
      // @ts-ignore
      fileInputRef.current.click()
    }
  }

  useEffect(() => {
    if (props.report) {
      setUploadedFile(props?.report)
    }
  }, [props.report])

  const onDrop = (files: FileList | null, event?: any) => {
    if (event) {
      event.preventDefault()
    }
    if (files) {
      const allowedExtensions = ["pdf", "doc", "docx", "xlsx"]
      const fileExt = _.last(_.split(_.get(files, "[0].name"), "."))
      if (!_.includes(allowedExtensions, fileExt)) {
        return alert(
          "Only following formats are allowed: " + allowedExtensions.join(", ")
        )
      }
      setUploadedFile(files[0])
      return props.onSubmit(files && files[0])
    }
    return props.onSubmit()
  }

  const onDelete = () => {
    setUploadedFile(null)
    return props.onSubmit()
  }

  return (
    <Container>
      {uploadedFile ? (
        <Content>
          <Text>
            {uploadedFile?.name || uploadedFile?.key}
            <TrashIcon onClick={onDelete} />
          </Text>
        </Content>
      ) : (
        <Wrapper onClick={onFileInputClick}>
          <FileInput
            type="file"
            ref={fileInputRef}
            onChange={(event: any) => onDrop(event.target.files)}
          />
          <FileDrop onDrop={onDrop}>
            <Content>
              <Text $desktopOnly>
                <DragIcon />
                Drag and drop your health professional's details here,
              </Text>
              <Text>
                <Link>Browse</Link> files to upload or enter them manually
                below.
              </Text>
            </Content>
          </FileDrop>
        </Wrapper>
      )}
    </Container>
  )
}

export default DndFileUpload
