import React, { useEffect, useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import { apiPostQplDataz } from "api/queries"
import { QplDatazInput } from "api/types"
import { ToastContainer, toast } from "react-toastify"
import toastError from "utils/toastError"
import syncProfileWithSessionStorage from "components/QueryUserProfile/syncProfileWithSessionStorage"
import { UserType } from "api/types"
import NoQPLView from "./NoQPLView"
import WithQPLView from "./WithQPLView"

interface iQPL {
  show: boolean
  userProfile: UserType | null
}

const QPL: React.FC<iQPL> = ({ show, userProfile }) => {
  if (!show) return null
  if (!userProfile) return null

  const [userQPLList, setUserQPLList] = useState<string[]>([])
  const [userQPL, setUserQPL] = useState<QplDatazInput[]>([])
  const [loading, setLoading] = useState<string[]>([])
  useEffect(() => {
    if (!userProfile || !userProfile.qplDataz || !userProfile.qplDataz.length)
      return

    setUserQPLList(
      userProfile.qplDataz
        .filter(Boolean)
        .map(item => (item?.id ? item?.id : null))
        .filter(Boolean) as string[]
    )
    setUserQPL(userProfile.qplDataz as QplDatazInput[])
  }, [userProfile])

  useEffect(() => {
    if (!loading.length) return
    syncData(userQPL.filter(item => !loading.includes(item?.id as string)))
  }, [loading])

  const onClickDelete = (id: string) => {
    setLoading([...loading, id])
  }

  const syncData = async (qplList: QplDatazInput[]) => {
    try {
      if (!userProfile) return
      const result = await apiPostQplDataz(qplList)
      setUserQPLList(
        result.qplDataz
          ?.filter(Boolean)
          .map(item => (item?.id ? item?.id : null))
          .filter(Boolean) as string[]
      )
      syncProfileWithSessionStorage({ ...userProfile, ...(result as UserType) })
    } catch (e) {
      console.error("Error deleting item", e)
      toastError(toast, "Something went wrong. Reload the page.")
      return
    }
  }

  if (!userQPLList.length) return <NoQPLView />
  return (
    <>
      <WithQPLView
        userProfile={userProfile}
        loadingList={loading}
        userQPLList={userQPLList}
        onClickDelete={onClickDelete}
      />
      <ToastContainer />
    </>
  )
}

export default QPL
